import _ from 'underscore';
import { idbHelper, vehicleHelper } from 'helpers';
import {
  addItemToArray,
  addObjectToObject,
  setProspectDataRegionFromLocalStorage,
  updateData,
  updateSelectedUnit,
} from 'store/prospect/tasks';
import { getCompanyDealersByOrgNr } from 'store/company/tasks';
import { store } from 'store';
import * as text from 'text-content';
import moment from 'moment';

export const prospectHelper = {
  /**
   * Extract active values from data object to match backend query handler.
   * This is used when we do a search in Prospektera, update count in Prospektera, supertemp subscriptions etc.
   *
   * @param payload.data - obj
   * @param payload.skipRebuildToBackendObjectStructure - bool - Sometimes we use this function with saved supertemp criterias etc, that is already in the backend format. We only need the rebuild when we use the prospect reducer data object structure.
   */
  createQueryObjectFromDataObject: (payload) => {
    try {
      let query = JSON.parse(JSON.stringify(payload.data));

      if (!query) {
        return {};
      }

      if (!payload.skipRebuildToBackendObjectStructure) {
        query =
          prospectHelper.rebuildFrontendDataObjectToBackendDataObject(query);
      }

      // Coupling have values including 'other' (4) and 'another' (11), but we only show button for 'other' and search for both values.
      if (query.car) {
        if (
          query.car.coupling1 &&
          query.car.coupling1.find((num) => num.val === '4')
        ) {
          query.car.coupling1.push({ val: '11' });
        }
        if (
          query.car.coupling2 &&
          query.car.coupling2.find((num) => num.val === '4')
        ) {
          query.car.coupling2.push({ val: '11' });
        }
      }
      // Strip search query from inactive values.
      query = prospectHelper.removeAndClean(query);
      query = prospectHelper.removeEmptyValues(query);

      if (query?.car?.inService) {
        let which = query.car.inService[0].val;
        switch (which) {
          case 'Förfallen':
            delete query.car.inspectionExpiration;
            delete query.car.inspectionValidity;
            break;
          case 'Avställd':
            delete query.car.inspectionOverdue;
            delete query.car.inspectionValidity;
            break;
          case 'I trafik':
            delete query.car.inspectionExpiration;
            delete query.car.inspectionOverdue;
            break;
          default:
            break;
        }
      } else {
        if (query.car) {
          delete query.car.inspectionExpiration;
          delete query.car.inspectionValidity;
          delete query.car.inspectionOverdue;
        }
      }

      // query = prospectHelper.removeDependentRangeValues(query);
      // TODO:
      // these helper functions are getting called on each object in prospektera, so something is wrong or I don't understand what is up.
      // fix or figure out why!
      if (!payload.skipRebuildToBackendObjectStructure) {
        /*
          We have one structure for brand percentages in frontend that makes it easy to work with.
          Backend expects another structure, which makes it easy to insert values to elastic query.
          So we rebuild brandPercentages object to an array that holds range objects in children array.

          Frontend: brandPercentages is an object with brand names as keys, which holds a 'range' object.
          Backend (legacy): brandPercentages is an array with objects, each object hold brand name as 'val'
                  and a 'children' array that holds a legacy slider object, example: {from: 20, to: 100}.

          We convert frontend -> backend structure here, and in updateDataFromQuery we convert backend -> frontend.
           */
        if (
          query.car &&
          query.car.brandPercentages &&
          Object.keys(query.car.brandPercentages).length
        ) {
          const arr = [];
          for (const prop in query.car.brandPercentages) {
            arr.push({
              children: [query.car.brandPercentages[prop]],
              val: prop,
            });
          }
          query.car.brandPercentages = arr;
        }
      }

      return query;
    } catch (err) {
      console.error('Error in createQueryObjectFromDataObject', err);
      return {};
    }
  },
  /**
   * Helper function that iterates an object, such as the data object, and runs every range or value object through a sent in function.
   * This makes it easy to set values or make other adjustments for all range/value objects in data object (or subsection of data).
   * We can also choose to remove an object via applyFunction - just return null.
   *
   * @param obj - object
   * @param applyFunction - func - Function that handles the objects.
   */
  findAllObjectsAndHandle: (obj, applyFunction = null) => {
    Object.keys(obj).forEach((key, i) => {
      if (Array.isArray(obj[key])) {
        const iterateArray = (arr) => {
          return arr.map((num) => {
            if (Array.isArray(num)) {
              num = iterateArray(num);
            } else if (typeof num === 'object' && Object.keys(num).length) {
              if (num.type && (num.type === 'range' || num.type === 'value')) {
                if (Array.isArray(num.children)) {
                  num.children = iterateArray(num.children);
                }
                num = applyFunction(num); // Run value and range objects through applyFunction.
              } else {
                num = prospectHelper.findAllObjectsAndHandle(
                  num,
                  applyFunction
                );
              }
            }

            return num;
          });
        };

        obj[key] = iterateArray(obj[key]);
      } else if (obj[key] && typeof obj[key] === 'object') {
        if (
          obj[key].type &&
          (obj[key].type === 'range' ||
            obj[key].type === 'value' ||
            obj[key].type === 'rangeUnbounded')
        ) {
          obj[key] = applyFunction(obj[key]); // Run value and range objects through applyFunction.
        } else {
          obj[key] = prospectHelper.findAllObjectsAndHandle(
            obj[key],
            applyFunction
          );
        }
      }
    });

    return obj;
  },
  /**
   * Takes a data object and finds the correct nested object by keyPath and returns it.
   *
   * @param data
   * @param keyPath
   */
  findObject: (data, keyPath) => {
    let current;

    // Find value.
    for (let i = 0; i < keyPath.length; i += 1) {
      let key = keyPath[i];
      if (Array.isArray(current)) {
        current = current.find((num) => num.val === key);

        if (current && current.children && i !== keyPath.length - 1) {
          current = current.children;
        }
      } else if (current && current[key]) {
        current = current[key];
      } else if (i === 0) {
        current = data[key];
      } else {
        return console.error('Could not find value in findObject', keyPath);
      }
    }

    return current;
  },
  getDataObjectTyped: (initial = false) => {
    let tc;
    if (initial) {
      // When called from reducer, store wont be initialized and we cant access it.
      tc = text.swedish;
    } else {
      tc = store?.getState()?.user?.info?.lang
        ? store.getState().user.info.lang === 'en'
          ? text.english
          : text.swedish
        : text.swedish;
    }

    return {
      car: {
        aproxMileage: {
          from: null,
          min: 0,
          max: 600000,
          text: tc.service_weight,
          to: null,
          type: 'range',
          unit: 'mil',
        },
        boughtCondition: [
          { active: false, text: tc.new, type: 'value', val: 'new' },
          { active: false, text: tc.used, type: 'value', val: 'old' },
        ],
        brandPercentages: {},
        brands: [], // Bulk data for this selection is stored in indexedDb, this holds the brands that are currently relevant for selected carTypes.
        carAge: {
          from: null,
          max: 780,
          min: 0,
          text: tc.carAge,
          to: null,
          type: 'range',
          unit: tc.monthsShort,
          selected: 'months',
          options: ['months', 'years'],
        },
        carAgeFTIT: {
          from: null,
          max: 780,
          min: 0,
          text: tc.carAge,
          to: null,
          type: 'range',
          unit: tc.monthsShort,
          selected: 'months',
          options: ['months', 'years'],
        },
        carType: [
          {
            active: false,
            children: [],
            text: tc.atr,
            type: 'value',
            val: 'ATR',
          },
          {
            active: false,
            children: [],
            text: tc.atv,
            type: 'value',
            val: 'ATV',
          },
          {
            active: false,
            children: [],
            text: tc.bu,
            type: 'value',
            val: 'BU',
          },
          {
            active: false,
            children: [],
            text: tc.hb,
            type: 'value',
            val: 'HB',
          },
          {
            active: false,
            children: [],
            text: tc.hv,
            type: 'value',
            val: 'HV',
          },
          {
            active: false,
            children: initial ? [] : vehicleHelper.getLbSegmentTyped(),
            text: tc.lb,
            type: 'value',
            val: 'LB',
          },
          {
            active: false,
            children: [],
            text: tc.mc,
            type: 'value',
            val: 'MC',
          },
          {
            active: false,
            children: [],
            text: tc.mp,
            type: 'value',
            val: 'MP',
          },
          {
            active: false,
            children: [],
            text: tc.mr,
            type: 'value',
            val: 'MR',
          },
          {
            active: false,
            children: initial ? [] : vehicleHelper.getPbSegmentTyped(),
            text: tc.pb,
            type: 'value',
            val: 'PB',
          },
          {
            active: false,
            children: [],
            text: tc.ss,
            type: 'value',
            val: 'SS',
          },
          {
            active: false,
            children: [],
            text: tc.sv,
            type: 'value',
            val: 'SV',
          },
          {
            active: false,
            children: initial ? [] : vehicleHelper.getTlbSegmentTyped(),
            text: tc.tlb,
            type: 'value',
            val: 'TLB',
          },
          {
            active: false,
            children: [],
            text: tc.tr,
            type: 'value',
            val: 'TR',
          },
        ],
        carYear: {
          from: null,
          max: new Date().getFullYear(),
          min: 1900,
          text: tc.carYear,
          to: null,
          type: 'range',
          unit: tc.year,
        },
        changeTemp: [
          { active: false, text: tc.supertemp, type: 'value', val: 'superhot' },
        ],
        co2: {
          from: null,
          max: 400,
          min: 0,
          text: tc.co2,
          to: null,
          type: 'range',
          unit: 'g/km',
        },
        climateClassification: [
          { active: false, text: '2000', type: 'value', val: '2000' },
          { active: false, text: '2005', type: 'value', val: '2005' },
          { active: false, text: '2005PM', type: 'value', val: '2005PM' },
          { active: false, text: '2008', type: 'value', val: '2008' },
          { active: false, text: 'EEV', type: 'value', val: 'EEV' },
          { active: false, text: 'el', type: 'value', val: 'el' },
          { active: false, text: 'hybrid', type: 'value', val: 'hybrid' },
          { active: false, text: 'mk3', type: 'value', val: 'mk3' },
          { active: false, text: 'mk2', type: 'value', val: 'mk2' },
          { active: false, text: 'mk1', type: 'value', val: 'mk1' },
        ],
        coupling1: initial ? [] : vehicleHelper.getCouplingTyped(),
        coupling2: initial ? [] : vehicleHelper.getCouplingTyped(),
        cylinderVolume: {
          from: null,
          max: 20000,
          min: 49,
          text: tc.cylinderVolume,
          to: null,
          type: 'range',
          unit: 'cc',
        },
        dealerSalesmenType: [
          {
            active: false,
            text: tc.hasBoughtFrom,
            type: 'value',
            val: 'include',
          },
          {
            active: false,
            text: tc.hasNotBoughtFrom,
            type: 'value',
            val: 'exclude',
          },
        ],
        dealerSalesmen: [], // Bulk data for this selection is stored in indexedDb, this only holds selected values.
        duo: [
          { active: false, text: tc.no, type: 'value', val: 0 },
          { active: false, text: tc.yes, type: 'value', val: 1 },
        ],
        emissionClass: [
          { active: false, text: 'EURO 1', type: 'value', val: '1' },
          { active: false, text: 'EURO 2', type: 'value', val: '2' },
          { active: false, text: 'EURO 3', type: 'value', val: '3' },
          { active: false, text: 'EURO 4', type: 'value', val: '4' },
          { active: false, text: 'EURO 5', type: 'value', val: '5' },
          { active: false, text: 'EURO 6', type: 'value', val: '6' },
          { active: false, text: 'EEV', type: 'value', val: 'EEV' },
          { active: false, text: 'TLB 3', type: 'value', val: 'III' },
          { active: false, text: 'TLB 4', type: 'value', val: 'IV' },
          { active: false, text: 'TLB 5', type: 'value', val: 'V' },
          { active: false, text: 'TLB 6', type: 'value', val: 'VI' },
        ],
        engineStrength: {
          from: null,
          max: 1000,
          min: 0,
          text: tc.engineStrength,
          to: null,
          type: 'range',
          unit: 'hk',
        },
        finance: [
          { active: false, text: tc.leasing, type: 'value', val: 'leasing' },
          { active: false, text: tc.credit, type: 'value', val: 'credit' },
          { active: false, text: tc.cash, type: 'value', val: 'cash' },
        ],
        fourwheel: [
          { active: false, text: tc.nonFourwheel, type: 'value', val: 0 },
          { active: false, text: tc.fourwheel, type: 'value', val: 1 },
        ],
        freeTextBrands: [],
        freeTextModels: [],
        fuel: [
          { active: false, text: tc.gasoline, type: 'value', val: 'BENSIN' },
          { active: false, text: tc.diesel, type: 'value', val: 'DIESEL' },
          { active: false, text: tc.ethanol, type: 'value', val: 'ETANOL' },
          {
            active: false,
            text: tc.pluginHybridGasoline,
            type: 'value',
            val: 'LADDHYBRID BENSIN',
          },
          {
            active: false,
            text: tc.hybridGasoline,
            type: 'value',
            val: 'ELHYBRID BENSIN',
          },
          { active: false, text: tc.electricity, type: 'value', val: 'EL' },
          {
            active: false,
            text: tc.fordonsgas,
            type: 'value',
            val: 'FORDONSGAS',
          },
          {
            active: false,
            text: tc.electricHybridDiesel,
            type: 'value',
            val: 'ELHYBRID DIESEL',
          },
          {
            active: false,
            text: tc.electricHybrid,
            type: 'value',
            val: 'ELHYBRID',
          },
          {
            active: false,
            text: tc.pluginHybridDiesel,
            type: 'value',
            val: 'LADDHYBRID DIESEL',
          },
          { active: false, text: tc.lng, type: 'value', val: 'LNG' },
          { active: false, text: tc.cng, type: 'value', val: 'CNG' },
          {
            active: false,
            text: tc.liquidpetroleumgas,
            type: 'value',
            val: 'MOTORGAS',
          },
          {
            active: false,
            text: tc.electricHybridEthanol,
            type: 'value',
            val: 'ELHYBRID ETANOL',
          },
          {
            active: false,
            text: tc.pluginHybrid,
            type: 'value',
            val: 'LADDHYBRID',
          },
          { active: false, text: tc.hydrogen, type: 'value', val: 'VÄTGAS' },
          {
            active: false,
            text: tc.hybridFuelCell,
            type: 'value',
            val: 'ELHYBRID BRÄNSLECELL',
          },
          {
            active: false,
            text: tc.pluginHybridEthanol,
            type: 'value',
            val: 'LADDHYBRID ETANOL',
          },
          {
            active: false,
            text: tc.hybridDiesel,
            type: 'value',
            val: 'HYBRID DIESEL',
          },
        ],
        fuelDepletion_1: {
          from: null,
          to: null,
          max: 20,
          min: 0,
          text: tc.fuelDepletion_1,
          type: 'range',
          unit: '/100km',
        },
        height: {
          from: null,
          max: 5000,
          min: 0,
          text: tc.height,
          to: null,
          type: 'range',
          unit: 'mm',
        },
        import: [
          { active: false, text: tc.no, type: 'value', val: 0 },
          { active: false, text: tc.yes, type: 'value', val: 1 },
        ],
        inService: [
          { active: false, text: tc.inService, type: 'value', val: 'I trafik' },
          {
            active: false,
            text: tc.notInService,
            type: 'value',
            val: 'Avställd',
          },
          { active: false, text: tc.expired, type: 'value', val: 'Förfallen' },
        ],
        inspectionExpiration: {
          from: null,
          max: 120,
          min: 0,
          text: tc.inspectionExpired,
          to: null,
          type: 'range',
          unit: tc.monthsShort,
        },
        inspectionOverdue: {
          from: 1,
          max: 90,
          min: 1,
          text: tc.inspectionOverdue,
          to: 30,
          type: 'range',
          unit: tc.days,
        },
        inspectionValidity: {
          from: null,
          max: 180,
          min: 0,
          text: tc.inspectionValid,
          to: null,
          type: 'range',
          unit: tc.days,
        },
        leasingOwnersType: [
          {
            active: false,
            text: tc.hasLeasingAt,
            type: 'value',
            val: 'include',
          },
          {
            active: false,
            text: tc.hasNotLeasingAt,
            type: 'value',
            val: 'exclude',
          },
        ],
        leasingOwners: [], // Bulk data for selection is stored in indexedDb, this only holds selected values.
        length: {
          from: null,
          max: 9000,
          min: 0,
          text: tc.length,
          to: null,
          type: 'range',
          unit: 'mm',
        },
        maxExtraWeight: {
          from: null,
          max: 96000,
          min: 0,
          text: tc.maxExtraWeight,
          to: null,
          type: 'range',
          unit: 'kg',
        },
        maxLoadWeight: {
          from: null,
          max: 126720,
          min: 0,
          text: tc.maxLoadWeight,
          to: null,
          type: 'range',
          unit: 'kg',
        },
        possessionTime: {
          from: null,
          max: 180,
          min: 0,
          text: tc.possessionTime,
          to: null,
          type: 'range',
          unit: tc.monthsShort,
          selected: 'months',
          options: ['days', 'months', 'years'],
        },
        priorBrands: [], // Bulk data for selection is stored in indexedDb, this only holds selected values.
        pulley: [
          { active: false, text: tc.no, type: 'value', val: 0 },
          { active: false, text: tc.yes, type: 'value', val: 1 },
        ],
        regDate: {
          from: null,
          max: new Date().getFullYear(),
          min: 1950,
          text: tc.regDate,
          to: null,
          type: 'range',
          unit: tc.year,
        },
        seatsBus: {
          from: null,
          max: 170,
          min: 0,
          text: tc.seatsBus,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        seatsCar: [
          { active: false, text: '2', type: 'value', val: 2 },
          { active: false, text: '4', type: 'value', val: 4 },
          { active: false, text: '5', type: 'value', val: 5 },
          { active: false, text: '> 5', type: 'value', val: '6+' },
        ],
        segmentsPBNew: initial ? [] : vehicleHelper.getSegmentPbTyped(),
        segmentsSVEuro: initial
          ? []
          : vehicleHelper.getSVEuroKarossSegmentsTyped(),
        segmentSVOld: [],
        segmentsTLBEuro: initial
          ? []
          : vehicleHelper.getTLBEuroKarossSegmentsTyped(),
        segmentsLBEuro: initial
          ? []
          : vehicleHelper.getTLBEuroKarossSegmentsTyped(),
        segmentTLBOld: [],
        segmentLBOld: [],
        shaftAmount: {
          from: null,
          max: 4,
          min: 1,
          text: tc.shaftAmount,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        shaftDistance_1: {
          from: null,
          max: 7000,
          min: 0,
          text: tc.shaftDistance1,
          to: null,
          type: 'range',
          unit: 'mm',
        },
        shaftDistance_2: {
          from: null,
          max: 7000,
          min: 0,
          text: tc.shaftDistance2,
          to: null,
          type: 'range',
          unit: 'mm',
        },
        shaftDistance_3: {
          from: null,
          max: 7000,
          min: 0,
          text: tc.shaftDistance3,
          to: null,
          type: 'range',
          unit: 'mm',
        },
        sellersType: [
          {
            active: false,
            text: tc.hasBoughtAt,
            type: 'value',
            val: 'include',
          },
          {
            active: false,
            text: tc.hasNotBoughtAt,
            type: 'value',
            val: 'exclude',
          },
        ],
        sellers: [], // Bulk data for selection is stored in indexedDb, this only holds selected values.
        sellersGroups: [], // Loaded from backend.
        service_weight: {
          from: null,
          max: 60000,
          min: 0,
          text: tc.service_weight,
          to: null,
          type: 'range',
          unit: 'kg',
        },
        submission: [
          { active: false, text: tc.carSubmissionA, type: 'value', val: 'A' },
          { active: false, text: tc.carSubmissionM, type: 'value', val: 'M' },
        ],
        supertempPrice: {
          from: null,
          max: 5000000,
          min: 0,
          text: tc.supertempPrice,
          to: null,
          type: 'range',
          unit: tc.swedishCrowns,
        },
        usage: [
          { active: false, text: tc.taxi, type: 'value', val: 'taxi' },
          { active: false, text: tc.rental, type: 'value', val: 'rental' },
          { active: false, text: tc.noTaxi, type: 'value', val: 'noTaxi' },
          { active: false, text: tc.noRental, type: 'value', val: 'noRental' },
        ],
        weight: {
          from: null,
          max: 60000,
          min: 0,
          text: tc.weight,
          to: null,
          type: 'range',
          unit: 'kg',
        },
        width: {
          from: null,
          max: 3000,
          min: 0,
          text: tc.width,
          to: null,
          type: 'range',
          unit: 'mm',
        },
      },
      prospect: {
        prospectTypes: [
          { active: false, text: tc.company, type: 'value', val: 'company' },
          {
            active: false,
            text: tc.privatePerson,
            type: 'value',
            val: 'privatePerson',
          },
        ],
        operation: {
          // Note that some of these is initiated as active.
          dealer: [
            { active: true, text: tc.exclude, type: 'value', val: 0 },
            { active: false, text: tc.include, type: 'value', val: 1 },
          ],
          government: [
            { active: false, text: tc.exclude, type: 'value', val: 0 },
            { active: true, text: tc.include, type: 'value', val: 1 },
          ],
          leasing_company: [
            { active: false, text: tc.exclude, type: 'value', val: 0 },
            { active: true, text: tc.include, type: 'value', val: 1 },
          ],
          scrap: [
            { active: true, text: tc.exclude, type: 'value', val: 0 },
            { active: false, text: tc.include, type: 'value', val: 1 },
          ],
          rental: [
            { active: false, text: tc.exclude, type: 'value', val: 0 },
            { active: true, text: tc.include, type: 'value', val: 1 },
          ],
          filial: [
            { active: false, text: tc.exclude, type: 'value', val: 0 },
            { active: true, text: tc.include, type: 'value', val: 1 },
          ],
        },
        vehicleCountPB: {
          from: null,
          max: 1000,
          min: 0,
          text: tc.vehicleCountPB,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountLB: {
          from: null,
          max: 1000,
          min: 0,
          text: tc.vehicleCountLB,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountTLB: {
          from: null,
          max: 1000,
          min: 0,
          text: tc.vehicleCountTLB,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountATR: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountATR,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountATV: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountATV,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountBU: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountBU,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountHB: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountHB,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountHV: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountHV,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountMC: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountMC,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountMP: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountMP,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountMR: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountMR,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountSS: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountSS,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountSV: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountSV,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        vehicleCountTR: {
          max: 1000,
          min: 0,
          text: tc.vehicleCountTR,
          from: null,
          to: null,
          type: 'range',
          unit: tc.aPiece,
        },
        carsOwned: {
          from: 1,
          min: 1,
          text: tc.carsOwned,
          to: null,
          type: 'rangeUnbounded',
          unit: tc.aPiece,
        },
        company: {
          companyTypes: [], // Bulk data for selection is stored in indexedDb, this only holds selected values.
          sniCodes: [],
          sniCodesExcluded: [],
          excludeSniCodes: {
            active: false,
            text: 'excludeSniCodes',
            type: 'value',
            val: 1,
          },
          numEmployees: [
            { active: false, text: '1 - 9', type: 'value', val: 1 },
            { active: false, text: '10 - 49', type: 'value', val: 2 },
            { active: false, text: '50 - 99', type: 'value', val: 3 },
            { active: false, text: '100 - 199', type: 'value', val: 4 },
            { active: false, text: '200 - 499', type: 'value', val: 5 },
            { active: false, text: '> 499', type: 'value', val: 6 },
          ],
          groupInsurance: [
            { active: false, text: 'Ja', type: 'value', val: 1 },
            { active: false, text: 'Nej', type: 'value', val: 0 },
          ],
          revenue: {
            from: null,
            max: 1000000,
            min: 0,
            text: tc.solidity,
            to: null,
            type: 'range',
            unit: 'tkr',
          },
          solidity: {
            from: null,
            max: 999,
            min: -999,
            text: tc.solidity,
            to: null,
            type: 'range',
            unit: '%',
          },
        },
        privatePerson: {
          age: {
            from: null,
            max: 100,
            min: 18,
            text: tc.age,
            to: null,
            type: 'range',
            unit: tc.year,
          },
          adressChangeDate: {
            from: null,
            max: 200,
            min: 0,
            text: tc.adressChangeDate,
            to: null,
            type: 'range',
            unit: tc.month,
            selected: 'months',
            options: ['months', 'days'],
          },
          gender: [
            { active: false, text: tc.female, type: 'value', val: 'K' },
            { active: false, text: tc.male, type: 'value', val: 'M' },
            {
              active: false,
              text: tc.genderNotProvided,
              type: 'value',
              val: 'E',
            },
          ],
        },
      },
      regions: {
        regions: [], // Bulk data for this selection is stored in indexedDb, this holds the lkp that are currently relevant for selected plats.
      },
      atlasRegions: {
        atlasRegions: [],
      },
      combineWithData: {
        excludeSavedLists: [],
        excludeMyCustomers: {
          active: false,
          text: 'excludeMyCustomers',
          type: 'value',
          val: 1,
        },
        exclusiveMyCustomers: [],
        exclusiveContracts: [],
        excludeContracts: [],
        excludeUserIdsGroups: [], // Loaded from backend.
        exclusiveUserIdsGroups: [], // Loaded from backend.
        excludeListsInverted: {
          active: true,
          text: 'ExcludeListsInverted',
          type: 'value',
          val: 0,
        }, // true here means we want to only show results from the selected lists, which is opposite of how it normally worked.
      },
    };
  },
  getHideFieldsObject: () => {
    return {
      // Fields should be values/keys in data object.
      // These determine which rows to hide and which values that gets inactivated in search query.
      ATR: {
        active: false,
        fields: [
          'climateClassification',
          'coupling1',
          'coupling2',
          'duo',
          'emissionClass',
          'fourwheel',
          'fuel',
          'fuelDepletion_1',
          'government',
          'height',
          'import',
          'leasingOwnersType',
          'seatsBus',
          'seatsCar',
          'shaftAmount',
          'shaftDistance_2',
          'shaftDistance_3',
          'useage',
        ],
      },
      ATV: {
        active: false,
        fields: [
          'carAge',
          'carYear',
          'co2',
          'cylinderVolume',
          'emissionClass',
          'climateClassification',
          'coupling1',
          'coupling2',
          'duo',
          'fourwheel',
          'fuel',
          'fuelDepletion_1',
          'import',
          'height',
          'leasingOwnersType',
          'length',
          'maxExtraWeight',
          'pulley',
          'seatsBus',
          'seatsCar',
          'shaftAmount',
          'shaftDistance_2',
          'shaftDistance_3',
          'submission',
          'usage',
          'width',
        ],
      },
      BU: {
        active: false,
        fields: [
          'climateCar',
          'cylinderVolume',
          'height',
          'length',
          'maxExtraWeight',
          'seatsCar',
          'width',
        ],
      },
      HB: {
        active: false,
        fields: [
          'brandPercentages',
          'climateClassification',
          'co2',
          'coupling1',
          'coupling2',
          'cylinderVolume',
          'duo',
          'filial',
          'fourwheel',
          'fuel',
          'fuelDepletion_1',
          'government',
          'leasing_company',
          'maxExtraWeight',
          'maxLoadWeight',
          'pulley',
          'seatsBus',
          'scrap',
          'shaftAmount',
          'shaftDistance_1',
          'shaftDistance_2',
          'shaftDistance_3',
          'service_weight',
          'usage',
        ],
      },
      HV: {
        active: false,
        fields: [
          'brandPercentages',
          'climateClassification',
          'climateCar',
          'co2',
          'coupling1',
          'coupling2',
          'cylinderVolume',
          'duo',
          'engineStrength',
          'emissionClass',
          'filial',
          'fourwheel',
          'fuel',
          'fuelDepletion_1',
          'fuel_depletion_usage1',
          'government',
          'leasing_company',
          'maxExtraWeight',
          'maxLoadWeight',
          'pulley',
          'scrap',
          'seatsBus',
          'seatsCar',
          'shaftDistance_1',
          'shaftDistance_2',
          'shaftDistance_3',
          'submission',
          'usage',
        ],
      },
      LB: {
        active: false,
        fields: ['height', 'length', 'seatsBus', 'width'],
      },
      MC: {
        active: false,
        fields: [
          'co2',
          'emissionClass',
          'climateClassification',
          'coupling1',
          'coupling2',
          'duo',
          'fourwheel',
          'fuel',
          'fuelDepletion_1',
          'import',
          'height',
          'leasingOwnersType',
          'length',
          'maxExtraWeight',
          'pulley',
          'seatsBus',
          'seatsCar',
          'shaftAmount',
          'shaftDistance_2',
          'shaftDistance_3',
          'submission',
          'usage',
          'width',
        ],
      },
      MP: {
        active: false,
        fields: [
          'climateClassification',
          'co2',
          'coupling1',
          'coupling2',
          'duo',
          'emissionClass',
          'fourwheel',
          'fuel',
          'fuelDepletion_1',
          'government',
          'height',
          'import',
          'leasingOwnersType',
          'maxExtraWeight',
          'pulley',
          'seatsBus',
          'seatsCar',
          'shaftAmount',
          'shaftDistance_2',
          'shaftDistance_3',
          'submission',
          'useage',
          'width',
        ],
      },
      MR: {
        active: false,
        fields: [
          'leasingOwnersType',
          'government',
          'seatsBus',
          'seatsCar',
          'useage',
        ],
      },
      PB: {
        active: false,
        fields: ['height', 'length', 'width', 'seatsBus', 'shaftAmount'],
      },
      SS: {
        active: false,
        fields: [
          'carAge',
          // "carYear",
          'changeTemp',
          'co2',
          'emissionClass',
          'climateClassification',
          'coupling1',
          'coupling2',
          'duo',
          'fourwheel',
          'fuel',
          'fuelDepletion_1',
          'import',
          'height',
          'leasingOwnersType',
          'length',
          'maxExtraWeight',
          'pulley',
          'seatsBus',
          'seatsCar',
          'shaftAmount',
          'shaftDistance_2',
          'shaftDistance_3',
          'submission',
          'usage',
          'width',
        ],
      },
      SV: {
        active: false,
        fields: [
          'climateClassification',
          'climateCar',
          'co2',
          'coupling1',
          'coupling2',
          'cylinderVolume',
          'duo',
          'engineStrength',
          'emissionClass',
          'fourwheel',
          'fuel',
          'fuelDepletion_1',
          'fuel_depletion_usage1',
          'height',
          'length',
          'maxExtraWeight',
          'pulley',
          'seatsBus',
          'seatsCar',
          'submission',
          'width',
          'usage',
        ],
      },
      TLB: {
        active: false,
        fields: ['height', 'length', 'seatsBus', 'width'],
      },
      TR: {
        active: false,
        fields: [
          'climateClassification',
          'climateCar',
          'co2',
          'emissionClass',
          'seatsBus',
        ],
      },
    };
  },
  /**
   * Rebuild the data object to fit our preview component.
   */
  getPreviewObject: (obj) => {
    const tc = store?.getState()?.user?.info?.lang
      ? store?.getState()?.user.info.lang === 'en'
        ? text.english
        : text.swedish
      : text.swedish;

    const buildArrayWithValues = (arr) => {
      return arr
        .map((num) => {
          let obj = {
            label: num.text,
          };

          if (num.unit) {
            obj.unit = num.unit;
          }
          if (num.selected) {
            obj.selected = num.selected;
          }

          if (Array.isArray(num.children) && num.children.length) {
            obj.values = buildArrayWithValues(num.children);
          }

          if (num.hasOwnProperty('from')) {
            obj.from = num.from ? num.from : num.min;
          }

          if (num.hasOwnProperty('to')) {
            obj.to = num.to ? num.to : num.max;
          }

          return obj;
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label, 'sv'); // Sort alphabetically by label.
        });
    };

    const iterateObject = (obj, parent = {}, level = 0) => {
      for (const prop in obj) {
        if (Array.isArray(obj[prop])) {
          if (level === 0) {
            // We nest root level arrays inside an object so we render both header and section in <Preview>. This case doesn't exist in our current data structure though.
            parent[tc[prop]] = {
              [prop]: buildArrayWithValues(obj[prop]),
            };
          } else {
            parent[prop] = buildArrayWithValues(obj[prop]);
          }
        } else if (typeof obj[prop] === 'object') {
          if (
            obj[prop].hasOwnProperty('from') ||
            obj[prop].hasOwnProperty('to')
          ) {
            parent[prop] = {
              from: obj[prop].from ? obj[prop].from : obj[prop].min,
              label: prop,
              to: obj[prop].to ? obj[prop].to : obj[prop].max,
              unit: obj[prop].unit,
              selected: obj[prop].selected,
            };
          } else {
            // Sort object props by translation to display alphabetically in preview component.
            const sorted = Object.keys(obj[prop])
              .sort((a, b) => {
                if (a === 'prospectTypes' || a === 'operation') {
                  // Exception, keep these values at top.
                  return -1;
                } else if (b === 'prospectTypes' || b === 'operation') {
                  return 1;
                } else {
                  try {
                    return tc[a].localeCompare(tc[b], 'sv');
                  } catch (err) {
                    // console.log(
                    //   "Error in iterateObject, translation missing for string: " +
                    //     a
                    // );
                    return 0;
                  }
                }
              })
              .reduce((acc, key) => {
                acc[key] = obj[prop][key];
                return acc;
              }, {});

            parent[prop] = iterateObject(sorted, parent[prop], level + 1);
          }
        }
      }

      return parent;
    };

    // Clone and clean data beforehand.
    let data = JSON.parse(JSON.stringify(obj));
    data = prospectHelper.removeAndClean(data, true);
    data = prospectHelper.removeEmptyValues(data);

    // Special case, rebuild this one to array first.
    if (data.car && data.car.brandPercentages) {
      const arr = [];
      for (const prop in data.car.brandPercentages) {
        arr.push(data.car.brandPercentages[prop]);
      }
      data.car.brandPercentages = arr;
    }
    // Special case where we have no data.car.inService but do have data.car.inspectionExpiration
    // or data.car.inspectionExpirationDate, we need to remove the latter.
    if (
      !data?.car?.inService &&
      (data?.car?.inspectionExpiration || data?.car?.inspectionValidity)
    ) {
      if (data.car.inspectionExpiration) delete data.car.inspectionExpiration;
      if (data.car.inspectionValidity) delete data.car.inspectionValidity;
    }
    // Special case where we have data.car.inService, we check which is selected to only show preview for
    // correct range for selected value.
    if (data?.car?.inService && data?.car?.inService?.length) {
      let which = data.car.inService[0].val;
      if (which === 'Avställd') {
        delete data.car.inspectionValidity;
        delete data.car.inspectionOverdue;
      } else if (which === 'I trafik') {
        delete data.car.inspectionExpiration;
        delete data.car.inspectionOverdue;
      } else if (which === 'Förfallen') {
        delete data.car.inspectionExpiration;
        delete data.car.inspectionValidity;
      }
    } else {
      if (data && data.car) {
        delete data.car.inspectionOverdue;
        delete data.car.inspectionValidity;
        delete data.car.inspectionExpiration;
      }
    }
    // Also a special case, rebuild the operation object to be an array with only include values.
    // This fix is not crucial, we can safely remove it... just looks nicer in Preview.
    if (data.prospect && data.prospect.operation) {
      const arr = [];
      for (const prop in data.prospect.operation) {
        const includeObj = data.prospect.operation[prop].find(
          (num) => num.val === 1 && num.active
        );
        if (includeObj) {
          includeObj.text = tc[prop] ? tc[prop] : prop;
          arr.push(includeObj);
        }
      }

      if (arr.length) {
        data.prospect.operation = arr;
      } else {
        delete data.prospect.operation;
      }
    }

    if (data?.prospect?.carsOwned) {
      if (!data.prospect.carsOwned.to) delete data.prospect.carsOwned;
    }

    if (data?.car?.possessionTime) {
      const { selected } = data.car.possessionTime;
      const unit =
        selected === 'days' ? 'Dagar' : selected === 'months' ? 'Mån' : 'År';
      data.car.possessionTime = {
        ...data.car.possessionTime,
        unit: unit,
      };
    }
    if (data?.combineWithData?.excludeListsInverted) {
      const { active } = data.combineWithData.excludeListsInverted;
      data.combineWithData.excludeListsInverted = {
        ...data.combineWithData.excludeListsInverted,
        text: active ? 'Inkludera listor' : 'Exkludera listor',
      };
    }
    // Start to build preview object.
    return iterateObject(data, {});
  },
  /**
   * Rebuild data object from backend structure (old list criterias) to new frontend structure.
   * See README for info.
   */
  rebuildBackendDataObjectToFrontendDataObject: (obj) => {
    if (obj.prospectTypes) {
      if (!obj.prospect) {
        obj.prospect = {};
      }
      obj.prospect = {
        ...obj.prospect,
        prospectTypes: obj.prospectTypes,
      };

      delete obj.prospectTypes;
    }

    if (obj.company) {
      if (!obj.prospect) {
        obj.prospect = {};
      }
      obj.prospect = {
        ...obj.prospect,
        company: obj.company,
      };

      delete obj.company;
    }

    if (obj.privatePerson) {
      if (!obj.prospect) {
        obj.prospect = {};
      }
      obj.prospect = {
        ...obj.prospect,
        privatePerson: obj.privatePerson,
      };

      delete obj.privatePerson;
    }

    if (obj.diverse && Object.keys(obj.diverse).length) {
      if (obj.diverse.excludeSavedLists) {
        if (!obj.combineWithData) {
          obj.combineWithData = {};
        }
        obj.combineWithData.excludeSavedLists = obj.diverse.excludeSavedLists;

        if (obj.diverse.excludeListsInverted) {
          obj.combineWithData.excludeListsInverted =
            obj.diverse.excludeListsInverted;
        }
      }

      if (obj.diverse.excludeMyCustomers) {
        if (!obj.combineWithData) {
          obj.combineWithData = {};
        }
        obj.combineWithData.excludeMyCustomers = obj.diverse.excludeMyCustomers;
      }

      if (obj.diverse.exclusiveMyCustomers) {
        if (!obj.combineWithData) {
          obj.combineWithData = {};
        }
        obj.combineWithData.exclusiveMyCustomers =
          obj.diverse.exclusiveMyCustomers;
      }

      if (obj.diverse.excludeUserIdsGroups) {
        if (!obj.combineWithData) {
          obj.combineWithData = {};
        }
        obj.combineWithData.excludeUserIdsGroups =
          obj.diverse.excludeUserIdsGroups;
      }

      if (obj.diverse.exclusiveUserIdsGroups) {
        if (!obj.combineWithData) {
          obj.combineWithData = {};
        }
        obj.combineWithData.exclusiveUserIdsGroups =
          obj.diverse.exclusiveUserIdsGroups;
      }

      if (obj.diverse.type) {
        if (!obj.prospect) {
          obj.prospect = {};
        }
        obj.prospect = {
          ...obj.prospect,
          operation: obj.diverse.type,
        };
      }

      if (obj.diverse.changeTemp) {
        if (!obj.car) {
          obj.car = {};
        }
        obj.car = {
          ...obj.car,
          changeTemp: obj.diverse.changeTemp,
        };
      }

      if (obj.diverse.supertempPrice) {
        if (!obj.car) {
          obj.car = {};
        }
        obj.car = {
          ...obj.car,
          supertempPrice: obj.diverse.supertempPrice,
        };
      }

      delete obj.diverse;
    }

    return obj;
  },
  /**
   * Rebuild or data object to a structure that our backend expects.
   * See README for info.
   */
  rebuildFrontendDataObjectToBackendDataObject: (obj) => {
    obj.diverse = {
      changeTemp: obj.car.changeTemp,
      supertempPrice: obj.car.supertempPrice,
      type: obj.prospect.operation,
    };

    delete obj.car.changeTemp;
    delete obj.car.supertempPrice;

    if (
      obj.prospect.prospectTypes[0].active &&
      obj.prospect.prospectTypes[0].val === 'company'
    ) {
      const exclude = obj.prospect.company.excludeSniCodes.active
        ? { active: true, text: 'excludeSniCodes', type: 'value', val: 1 }
        : {
            active: false,
            text: 'excludeSniCodes',
            type: 'value',
            val: 1,
          };
      const key = exclude.active ? 'sniCodesExcluded' : 'sniCodes';
      const opposite = exclude.active ? 'sniCodes' : 'sniCodesExcluded';
      const temp = {
        ...obj.prospect.company,
        [key]: obj.prospect.company.sniCodes.concat(
          obj.prospect.company.sniCodesExcluded
        ),
        [opposite]: null,
        excludeSniCodes: exclude,
      };
      obj.company = temp;
    }
    Object.keys(obj.prospect)
      .filter((key) => {
        const regEx = /^carsOwned/gi;
        return regEx.test(key);
      })
      .map((key) => {
        return { ...obj.prospect[key], key, to: obj.prospect[key].to || null };
      })
      .filter((obj) => {
        return (obj.from !== obj.min && !obj.to) || (obj.from && obj.to);
      })
      .forEach((prop) => {
        obj[prop.key] = prop;
      });

    Object.keys(obj.prospect)
      .map((key) => {
        const regEx = /^vehicleCount/gi;
        if (regEx.test(key)) {
          return {
            ...obj.prospect[key],
            key,
          };
        }

        return null;
      })
      .filter((obj) => {
        return (
          obj &&
          ((obj.from && obj.from !== obj.min) || (obj.to && obj.to !== obj.max))
        );
      })
      .forEach((prop) => (obj[prop.key] = prop));

    if (
      obj.prospect.prospectTypes[1].active &&
      obj.prospect.prospectTypes[1].val === 'privatePerson'
    ) {
      obj.privatePerson = obj.prospect.privatePerson;
    }

    if (obj.prospect.prospectTypes.find((num) => num.active)) {
      obj.prospectTypes = obj.prospect.prospectTypes;
    }

    delete obj.prospect;

    if (obj.combineWithData) {
      obj.diverse = {
        ...obj.diverse,
        ...obj.combineWithData,
      };
      delete obj.combineWithData;
    }
    return obj;
  },
  removeDependentRangeValues: (payload) => {
    let dependentRanges = [
      {
        key: 'car.inspectionOverdue',
        dependency: 'car.inService',
        value: 'Förfallen',
      },
    ];
    for (let range in dependentRanges) {
      if (payload[range.key] !== range.value) {
        delete payload[range.key];
      }
    }
    return payload;
  },
  removeEmptyValues: (payload) => {
    const removeValues = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && _.isEmpty(obj[key])) {
          if (obj[key] !== 0) {
            delete obj[key];
          }
        } else if (Array.isArray(obj[key])) {
          const iterateArray = (arr) => {
            arr = arr.filter((num) => num);
            return arr.map((num) => {
              if (Array.isArray(num.children)) {
                num.children = iterateArray(num.children);
              }
              return num;
            });
          };

          if (!obj[key].length) {
            delete obj[key];
          } else {
            obj[key] = iterateArray(obj[key]);
          }
        } else if (typeof obj[key] === 'object' && !_.isEmpty(obj[key])) {
          removeValues(obj[key]);
        } else if (!obj[key]) {
          if (obj[key] !== 0 && key !== 'carsOwned') {
            delete obj[key];
          }
        }
      });
    };

    // Three iterations to remove values on all levels.
    for (let i = 0; i < 3; i++) {
      removeValues(payload);
    }

    return payload;
  },
  /**
   * Return null for inactive objects and strip the active objects of unnecessary properties for a backend call.
   * Or when using this for other things thatn creating a backend query, we can keep properties for active objects.
   *
   * @param query - obj
   * @param saveProperties - bool (optional) - When true, we save properties for the active objects.
   */
  removeAndClean: (query, saveProperties) => {
    query = prospectHelper.findAllObjectsAndHandle(query, (num) => {
      if (num.type === 'range') {
        if (num.from || num.to) {
          if (!saveProperties) {
            for (const prop in num) {
              if (prop !== 'from' && prop !== 'to' && prop !== 'selected') {
                // When removing properties we keep 'from' and 'to', these are necessary for backend search query.
                // "selected" is also required because you need to be able to pass in months, days or other timespans.
                delete num[prop];
              }
            }
          }

          return num;
        } else {
          return null;
        }
      } else if (num.type === 'value') {
        if (num.active) {
          if (!saveProperties) {
            for (const prop in num) {
              if (prop !== 'children' && prop !== 'val') {
                // When removing properties we keep 'children' and 'val', these are necessary for backend search query.
                delete num[prop];
              }
            }
          }

          return num;
        } else {
          return null;
        }
      } else if (num.type === 'rangeUnbounded') {
        if (num.from || num.to) {
          if (!saveProperties) {
            for (const prop in num) {
              if (prop !== 'from' && prop !== 'to' && prop !== 'selected') {
                // When removing properties we keep 'from' and 'to', these are necessary for backend search query.
                // "selected" is also required because you need to be able to pass in months, days or other timespans.
                delete num[prop];
              }
            }
          }

          return num;
        } else {
          return null;
        }
      }
    });

    return query;
  },
  /**
   * Reset all value objects and range objects in a parent object.
   *
   * @param obj - obj
   */
  resetAllValuesInObject: (obj) => {
    return prospectHelper.findAllObjectsAndHandle(obj, (num) => {
      if (num.type === 'value') {
        num.active = false;
      } else if (num.type === 'range') {
        num.from = null;
        num.to = null;
      }

      return num;
    });
  },
  /**
   * Set fields in data object to inactive.
   *
   * In this function we handle normal value and range objects.
   * But we also handle parent properties. So if fields contain 'fuel' we
   * inactivate all the value objects in that array.
   *
   * This is for example used when a car type gets activated and we hide certain
   * fields for that type, then we also need to inactivate those fields.
   *
   * @param payload.data - obj - Data object
   * @param payload.fields - array - Array with field strings.
   */
  setFieldsToInactive: (payload) => {
    const { data, fields } = payload;

    const inactivateObject = (obj, key) => {
      if (obj.type === 'value') {
        obj.active = false;
      } else if (obj.type === 'range') {
        obj.from = null;
        obj.to = null;
      } else if (obj.type === 'rangeUnbounded') {
        obj.from = obj.min;
        obj.to = null;
      }

      return obj;
    };

    const iterateObject = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (
          obj[key].type &&
          (obj[key].type === 'range' ||
            obj[key].type === 'value' ||
            obj[key].type === 'rangeUnbounded')
        ) {
          obj[key] = inactivateObject(obj[key], key);
        } else if (Array.isArray(obj[key])) {
          const iterateArray = (arr, inactivateAllObjects) => {
            return arr.map((num) => {
              if (num.type && (num.type === 'range' || num.type === 'value')) {
                if (inactivateAllObjects || fields.includes(num.val)) {
                  num = inactivateObject(num);
                }
              }

              if (Array.isArray(num.children)) {
                num.children = iterateArray(num.children);
              }

              return num;
            });
          };

          obj[key] = iterateArray(obj[key], fields.includes(key));
        } else if (typeof obj[key] === 'object') {
          iterateObject(obj[key]);
        }
      });
    };

    return iterateObject(data);
  },
  /**
   * Update data object from a given query object.
   *
   * @param query - obj - The values should exists with the same key paths as our prospect data object.
   */
  updateDataFromQuery: async (query) => {
    // We don't need these values.
    delete query.extraFilters;
    delete query.page;
    let queryRegions = query.regions?.regions;
    delete query.regions;
    // prospectHelper.rebuildBackendDataObjectToFrontendDataObject messes up the regions
    // so we process that separately and delete the regions object if it exists
    if (queryRegions) setProspectDataRegionFromLocalStorage(queryRegions);
    // Might be an old data object structure, rebuild to new data object structure if necessary.
    query = prospectHelper.rebuildBackendDataObjectToFrontendDataObject(query);

    if (query.car && query.car.carType) {
      // Guard, make sure we run carType before brands.
      query = {
        ...query,
        car: {
          carType: query.car.carType,
          ...query.car,
        },
      };
    }

    let toggleListExclusive;
    if (query?.combineWithData?.excludeSavedLists?.length) {
      if (query.combineWithData.excludeListsInverted) {
        toggleListExclusive = false;
      } else {
        toggleListExclusive = true;
      }
    }

    /**
     * Update data.
     * Also handles some special cases where update isn't straight forward.
     * This is the core of recreate search.
     */
    const setData = async (keyPath, obj) => {
      const regEx = /^vehicleCount|carsOwned/gi;

      if (keyPath.some((str) => regEx.test(str))) {
        keyPath = ['prospect'].concat(keyPath);
      }
      const getFromIdb = ['companyTypes', 'dealerSalesmen'].some((x) =>
        keyPath.includes(x)
      );

      const getFromLists = ['excludeSavedLists'].some((x) =>
        keyPath.includes(x)
      );

      const listsExclusive = ['excludeListsInverted'].some((x) =>
        keyPath.includes(x)
      );

      const getFromColleagues = ['exclusiveMyCustomers'].some((x) =>
        keyPath.includes(x)
      );

      const myCustomersExclude = ['excludeMyCustomers'].some((x) =>
        keyPath.includes(x)
      );

      const hydrateFreeTextBrands = keyPath.includes('freeTextBrands');
      const hydrateFreeTextTLBSegments = keyPath.includes('segmentTLBOld');

      if (getFromIdb) {
        // Handle the cases where arrays per default is empty in prospect data,
        // we need to retrieve the object from idb bulk data and insert it.
        const item = await idbHelper.getStoreObject({
          store: keyPath[keyPath.length - 1],
          val: obj.val,
        });

        if (item) {
          item.active = true;
          addItemToArray({
            item: item,
            keyPath: keyPath,
            skipUpdateCount: true,
          });
        }
      } else if (myCustomersExclude) {
        await updateData({
          keyPath: keyPath,
          type: 'value',
          skipUpdateCount: true,
          value: obj.val,
        });
      } else if (getFromColleagues) {
        const userObj = store
          .getState()
          .user.colleagues.find((col) => obj.val === col.id);

        if (userObj) {
          addItemToArray({
            item: {
              active: true,
              text: userObj.name,
              type: 'value',
              val: obj.val,
            },
            keyPath: keyPath,
            skipUpdateCount: true,
          });
        }
      } else if (getFromLists) {
        // Retrieve the list object from lists in reducer state.
        const list = store
          .getState()
          .lists.lists.find((num) => num.id === obj.val);

        if (list) {
          addItemToArray({
            item: {
              active: true,
              text: list.name,
              type: 'value',
              val: obj.val,
            },
            keyPath: keyPath,
            skipUpdateCount: true,
          });

          await updateData({
            keyPath: ['combineWithData', 'excludeListsInverted'],
            type: 'value',
            skipUpdateCount: true,
            value: !toggleListExclusive,
          });
        }
      } else if (hydrateFreeTextBrands) {
        addItemToArray({
          item: {
            active: true,
            text: obj.val,
            type: 'value',
            val: obj.val,
          },
          keyPath: keyPath,
          skipUpdateCount: true,
        });
      } else if (hydrateFreeTextTLBSegments) {
        addItemToArray({
          item: {
            active: true,
            text: obj.val,
            type: 'value',
            val: obj.val,
          },
          keyPath: keyPath,
          skipUpdateCount: true,
        });
      } else if (keyPath[0] === 'car' && keyPath[1] === 'brands') {
        if (keyPath.length === 2) {
          // This is a brand.
          // Brands is a special case, historically users can have saved criterias with brands without a selected car type.
          // So we don't know what models this brand object should hold, hence why we create a new object here and set children to empty.
          // If a car type is selected though, the correct brand object should already be in state and this will only activate it.

          if (obj.children && obj.children.length) {
            // Add properties to children.
            obj.children = obj.children.map((child) => {
              return {
                active: child.hasOwnProperty('active') ? !!child.active : true,
                text: child.text ? child.text : child.val,
                type: 'value',
                val: child.val,
              };
            });
          }

          addItemToArray({
            item: {
              active: true,
              children: obj.children ? obj.children : [],
              text: obj.val,
              type: 'value',
              val: obj.val,
            },
            keyPath: [keyPath[0], keyPath[1]],
            skipUpdateCount: true,
          });
        } else if (keyPath.length === 3) {
          // This is a brand model.
          // With models we first need to see if parent brand exists in state.
          const brand = store
            .getState()
            .prospect.data.car.brands.find((num) => num.val === keyPath[2]);

          if (!brand) {
            // If not, add it to state with model in children array.
            addItemToArray({
              item: {
                active: true,
                children: [
                  {
                    active: true,
                    text: obj.val,
                    type: 'value',
                    val: obj.val,
                  },
                ],
                text: keyPath[2],
                type: 'value',
                val: keyPath[2],
              },
              keyPath: [keyPath[0], keyPath[1]],
              skipUpdateCount: true,
            });
          } else {
            // If brand already exists just add it.
            addItemToArray({
              item: {
                active: true,
                text: obj.val,
                type: 'value',
                val: obj.val,
              },
              keyPath: keyPath,
              skipUpdateCount: true,
            });
          }
        }
      } else if (keyPath.includes('brandPercentages')) {
        // Convert brandPercentages backend structure to frontend structure (see comment in createQueryObjectFromDataObject).
        if (obj.from || obj.to) {
          // Check that obj holds values so it's not a legacy parent object that holds values in children array (skip those, value object come next iteration).

          if (obj.to && Number.isFinite(+obj.to)) {
            obj.to = +obj.to;
          }

          let from = 0;
          if (obj.from) {
            if (obj.from < 0) {
              from = 0;
            } else {
              from = Number(obj.from);
            }
          }

          let to = 100;
          if (obj.to) {
            if (obj.to > 100) {
              to = 100;
            } else {
              to = Number(obj.to);
            }
          }

          // Restructure to 'range' object before updating state.
          obj = {
            from: from,
            min: 0,
            max: 100,
            to: to,
            type: 'range',
            unit: '%',
            text: keyPath[2],
          };

          addObjectToObject({
            key: keyPath[2],
            keyPath: [keyPath[0], keyPath[1]],
            object: obj,
          });
        }
      } else if (
        keyPath[0] === 'prospect' &&
        keyPath[1] === 'operation' &&
        keyPath.length === 3
      ) {
        // The prospect.operation arrays can only hold one true value.
        // So when one is set, set the other one to the opposite value.

        if (Number.isFinite(+obj.val)) {
          obj.val = +obj.val;
        }

        await updateData({
          keyPath: keyPath.concat([obj.val]),
          type: 'value',
          skipUpdateCount: true,
          value: true,
        });

        const reverseIndex = obj.val === 0 ? 1 : 0;

        await updateData({
          keyPath: keyPath.concat([reverseIndex]),
          type: 'value',
          skipUpdateCount: true,
          value: false,
        });
      } else if (keyPath.includes('sniCodes')) {
        if (obj.val) {
          addItemToArray({
            item: {
              active: true,
              text: 'loading...',
              type: 'value',
              val: obj.val,
            },
            keyPath: keyPath,
            skipUpdateCount: true,
          });
        }
      } else if (keyPath.includes('sniCodesExcluded')) {
        if (obj.val) {
          addItemToArray({
            item: {
              active: true,
              text: 'loading...',
              type: 'value',
              val: obj.val,
            },
            keyPath: keyPath.slice(0, 2).concat('sniCodes'),
            skipUpdateCount: true,
          });
        }
      } else if (keyPath.includes('atlasRegions')) {
        if (obj.val) {
          addItemToArray({
            item: {
              active: true,
              text: obj.text,
              type: 'value',
              val: obj.val,
            },
            keyPath: ['atlasRegions', 'atlasRegions'],
            skipUpdateCount: true,
          });
        }
      } else if (keyPath.includes('freeTextModels')) {
        if (obj.val) {
          addItemToArray({
            item: {
              active: true,
              text: obj.val,
              type: 'value',
              val: obj.val,
            },
            keyPath,
          });
        }
      } else if (keyPath.includes('sellers')) {
        if (obj.val) {
          const [dealerData] = await getCompanyDealersByOrgNr({ q: obj.val });

          if (dealerData) {
            addItemToArray({
              item: {
                active: true,
                text: dealerData.text,
                type: 'value',
                val: obj.val,
              },
              keyPath,
            });
          }
        }
      } else if (keyPath.includes('sellersType')) {
        if (obj.val) {
          addItemToArray({
            item: {
              active: true,
              text: obj.val,
              type: 'value',
              val: obj.val,
            },
            keyPath,
          });
        }
      } else if (keyPath.includes('leasingOwners')) {
        if (obj.val) {
          const [dealerData] = await getCompanyDealersByOrgNr({ q: obj.val });

          if (dealerData) {
            addItemToArray({
              item: {
                active: true,
                text: dealerData.text,
                type: 'value',
                val: obj.val,
              },
              keyPath,
            });
          }
        }
      } else if (keyPath.includes('leasingOwnersType')) {
        if (obj.val) {
          addItemToArray({
            item: {
              active: true,
              text: obj.val,
              type: 'value',
              val: obj.val,
            },
            keyPath,
          });
        }
      } else if (keyPath.includes('excludeSniCodes')) {
        await updateData({
          type: 'value',
          skipUpdateCount: true,
          value: true,
          keyPath: keyPath,
        });
      } else {
        // All other cases, regular data update.
        if (obj.val) {
          await updateData({
            keyPath: keyPath.concat([obj.val]),
            type: 'value',
            skipUpdateCount: true,
            value: true,
          });
        } else if (obj.from || obj.to) {
          if (obj.from && Number.isFinite(+obj.from)) {
            obj.from = +obj.from;
          }

          if (obj.to && Number.isFinite(+obj.to)) {
            obj.to = +obj.to;
          }

          await updateData({
            keyPath: keyPath,
            type: 'range',
            skipUpdateCount: true,
            value: {
              from: obj.from,
              to: obj.to,
            },
          });
          if (obj.selected) {
            updateSelectedUnit({ keyPath, selected: obj.selected });
          }
        }
      }
    };

    /**
     * Iterate object - find objects that should be set - send object to setData().
     */
    const traverseObject = async function traverseObjectCursive(
      obj,
      keyPath = []
    ) {
      let path = keyPath;

      for (let x = 0; x < Object.keys(obj).length; x++) {
        const prop = Object.keys(obj)[x];

        if (Array.isArray(obj[prop])) {
          const iterateArrayWithObjects = async (arr, theKeyPath) => {
            for (let i = 0; i < arr.length; i++) {
              if (
                arr[i] &&
                typeof arr[i] === 'object' &&
                Object.keys(arr[i]).length
              ) {
                if (
                  typeof arr[i].val !== 'undefined' ||
                  arr[i].from ||
                  arr[i].to
                ) {
                  await setData(theKeyPath, arr[i]);

                  if (Array.isArray(arr[i].children)) {
                    const newKeyPath = theKeyPath.concat([arr[i].val]);
                    await iterateArrayWithObjects(arr[i].children, newKeyPath);
                  }
                } else {
                  await traverseObjectCursive(arr[i], theKeyPath);
                }
              }
            }
          };

          const newPath = path.concat([prop]);
          await iterateArrayWithObjects(obj[prop], newPath);
        } else if (
          obj[prop] &&
          typeof obj[prop] === 'object' &&
          Object.keys(obj[prop]).length
        ) {
          const newKeyPath = path.concat([prop]);

          if (obj[prop].val || obj[prop].from || obj[prop].to) {
            await setData(newKeyPath, obj[prop]);
          } else {
            await traverseObjectCursive(obj[prop], newKeyPath);
          }
        }
      }
    };

    try {
      await traverseObject(query);
    } catch (err) {
      console.error('Error in updateDataFromQuery', err);
    }
  },
  convertDate: (obj, selected) => {
    let from, to;
    from = obj.to;
    to = obj.from;
    if (selected) {
      from = +moment()
        .subtract(from + 1, selected)
        .add(1, 'days')
        .format('YYYYMMDD');
      to = +moment().subtract(to, selected).format('YYYYMMDD');
    }
    return { from, to };
  },

  setNestedProp: function (obj, value, propPath) {
    const [head, ...rest] = propPath;

    !rest.length
      ? (obj[head] = value)
      : this.setNestedProp(obj[head], value, rest);
  },
};
