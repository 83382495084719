import React, { useContext, useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { textHelper } from 'helpers';
import LanguageContext from 'language-context';
import Activities from 'components/activities';
import CompanyInfo from './company_info';
import CompanyInLists from './company_in_lists';
import Contacts from 'components/contacts';
import CreateDeal from 'components/create_deal';
import Events from 'components/events';
import FleetWidget from 'components/fleet_widget';
import FleetWidgetHistoric from 'components/fleet_widget/fleet_widget_new';
import Loading from 'components/loading';
import PageHeader from 'components/page_header';
import SaveToList from 'components/save_to_list';
import { getDeals } from 'store/company/tasks';
import AddActivityToProspectPopup from '../activities/add_activity_to_prospect';
import KoncernStructure from 'components/koncern/koncern_structure';
import CompanyAdditionalInformation from 'components/company/company_additional_information';
import Icon from 'components/icon/icon';
import { resetActivitiesByTarget } from 'store/activities/tasks';
import styles from './company.module.css';
import { NavLink } from 'react-router-dom';
import { useDocumentListener } from 'hooks/use_document_listener';
import { clsx } from 'clsx';
const Company = ({ company, fleetAmount = null }) => {
  let { id } = useParams();
  id = textHelper.removeDashFromOrgnr(id);
  const [showCreateDeal, setShowCreateDeal] = useState(false);
  const [showSaveToList, setShowSaveToList] = useState(false);
  const [showAddActivity, setShowAddActivity] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // states for minimizing the different sections in company page
  const [minimizeCompanyInfo, setMinimizeCompanyInfo] = useState(false);
  const [minimizeAdditionalInformation, setMinimizeAdditionalInformation] =
    useState(false);
  const [minimizeLists, setMinimizeLists] = useState(true);
  const [minimizePlannedActivities, setMinimizePlannedActivities] =
    useState(true);
  const [minimizeContacts, setMinimizeContacts] = useState(false);
  const [minimizeKoncern, setMinimizeKoncern] = useState(false);
  const [minimizeFleet, setMinimizeFleet] = useState(false);
  const [minimizeActivity, setMinimizeActivity] = useState(false);
  const [minimizeFleetHistoric, setMinimizeFleetHistoric] = useState(false);
  const tc = useContext(LanguageContext);
  const defaultTabs = [
    { id: 'company', active: true, label: tc.company },
    { id: 'fleet', active: false, label: tc.fleet },
    {
      id: 'organization',
      active: false,
      label: 'Organisation',
    },
    { id: 'history', active: false, label: tc.history },
  ].filter(Boolean);
  const [tabs, setTabs] = useState(defaultTabs);
  const dropdownRef = useRef(null);
  const activeTabId = tabs.find((tab) => tab.active === true).id;

  const topOfPageRef = useRef(null);

  const isCompanyPage = window.location.pathname.includes('foretag');
  const closeDropdown = (e) => {
    if (!dropdownRef.current.contains(e.target)) setDropdownOpen(false);
  };
  const closeDropdownEscape = (e) => {
    if (e.key === 'Escape') {
      setDropdownOpen(false);
    }
  };
  useEffect(() => {
    topOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [id]);

  useEffect(() => {
    if (isCompanyPage) {
      resetActivitiesByTarget();
    }
  }, [id, isCompanyPage]);
  useDocumentListener('mousedown', closeDropdown);
  useDocumentListener('keydown', closeDropdownEscape);

  return (
    <div className="companyWrapper" ref={topOfPageRef}>
      <div className="companyWrapper__company">
        <div className="companyWrapper__company__header">
          <div className="companyWrapper__company__header__top">
            <PageHeader
              headline={
                company && company.info ? (
                  company.info.name
                ) : (
                  <Loading small={true} />
                )
              }
            />
          </div>
        </div>
        <div className="companyWrapper__company__content">
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <div
                ref={dropdownRef}
                onClick={() => {
                  if (dropdownOpen) return;
                  setDropdownOpen(true);
                }}
                className={clsx(styles.dropdown, dropdownOpen && styles.open)}
              >
                <Icon val="menu" />
                <div className={styles.dropdownContent}>
                  <div
                    onClick={() => {
                      setShowSaveToList(true);
                      setDropdownOpen(false);
                    }}
                    className={styles.actionWrapper}
                  >
                    <button
                      onClick={() => {
                        setShowSaveToList(true);
                        setDropdownOpen(false);
                      }}
                      className={styles.action}
                    >
                      {tc.saveToList}
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      setShowCreateDeal(true);
                      setDropdownOpen(false);
                    }}
                    className={styles.actionWrapper}
                  >
                    <button
                      onClick={() => {
                        setShowCreateDeal(true);
                        setDropdownOpen(false);
                      }}
                      className={styles.action}
                    >
                      {tc.createNewDeal}
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      setShowAddActivity(true);
                      setDropdownOpen(false);
                    }}
                    className={styles.actionWrapper}
                  >
                    <button
                      onClick={() => {
                        setShowAddActivity(true);
                        setDropdownOpen(false);
                      }}
                      className={styles.action}
                    >
                      {tc.addActivity}
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.tabs}>
                {tabs.map((tab) => {
                  if (tab.id === 'organization') {
                    if (!company?.koncern?.hasKoncern) return null;
                  }
                  return (
                    <button
                      key={tab.id}
                      className={clsx(
                        styles.button,
                        tab.active ? styles.active : styles.inactive
                      )}
                      onClick={() => {
                        setTabs((tabs) =>
                          tabs.map((t) => {
                            return {
                              ...t,
                              active: t.id === tab.id ? true : false,
                            };
                          })
                        );
                      }}
                    >
                      {tab.label}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className={styles.tabs}>
              <NavLink
                to={`/reports?rapport=27&filterName=Orgnr&filterValue=${id}`}
                className={clsx(styles.button, styles.link)}
              >
                {tc.fleetReport}
                <Icon val="launch" />
              </NavLink>
              <NavLink
                to={`https://www.allabolag.se/${id}`}
                className={clsx(styles.button, styles.link)}
              >
                Allabolag.se
                <Icon val="launch" />
              </NavLink>
            </div>
          </div>

          <div
            className={activeTabId === 'company' ? styles.show : styles.hide}
          >
            <div
              className={
                minimizeCompanyInfo
                  ? 'companyWrapper__company__content__item minimize'
                  : 'companyWrapper__company__content__item'
              }
              style={{
                cursor: minimizeCompanyInfo ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (minimizeCompanyInfo) {
                  setMinimizeCompanyInfo(false);
                }
              }}
            >
              <CompanyInfo
                id={id}
                isMinimized={minimizeCompanyInfo}
                onMinimizeChange={setMinimizeCompanyInfo}
                isCompanyPage={isCompanyPage}
                fleetAmount={fleetAmount}
              />
              <div className="companyWrapper__company__content__item__block__header__icon">
                {minimizeCompanyInfo ? (
                  <Icon
                    val="maximize"
                    onClick={() => setMinimizeCompanyInfo(false)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                ) : (
                  <Icon
                    val="minimize"
                    onClick={() => setMinimizeCompanyInfo(true)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                )}
              </div>
            </div>
            <div
              className={
                minimizeAdditionalInformation
                  ? 'companyWrapper__company__content__item minimize'
                  : 'companyWrapper__company__content__item'
              }
              style={{
                cursor: minimizeAdditionalInformation ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (minimizeAdditionalInformation) {
                  setMinimizeAdditionalInformation(false);
                }
              }}
            >
              <CompanyAdditionalInformation
                companyInfo={company.info}
                isMinimized={minimizeAdditionalInformation}
                onMinimizeChange={setMinimizeAdditionalInformation}
                isCompanyPage={isCompanyPage}
              />
              <div className="companyWrapper__company__content__item__block__header__icon">
                {minimizeAdditionalInformation ? (
                  <Icon
                    val="maximize"
                    onClick={() => setMinimizeAdditionalInformation(false)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                ) : (
                  <Icon
                    val="minimize"
                    onClick={() => setMinimizeAdditionalInformation(true)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                )}
              </div>
            </div>
            <div
              className={
                minimizeContacts
                  ? 'companyWrapper__company__content__item minimize'
                  : 'companyWrapper__company__content__item'
              }
              style={{
                cursor: minimizeContacts ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (minimizeContacts) {
                  setMinimizeContacts(false);
                }
              }}
            >
              <Contacts
                entityId={id}
                entityName={
                  company && company.info ? (
                    company.info.name
                  ) : (
                    <Loading small={true} />
                  )
                }
                entityType="company"
                isMinimized={minimizeContacts}
                onMinimizeChange={setMinimizeContacts}
                isCompanyPage={isCompanyPage}
              />
              <div className="companyWrapper__company__content__item__block__header__icon">
                {minimizeContacts ? (
                  <Icon
                    val="maximize"
                    onClick={() => setMinimizeContacts(false)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                ) : (
                  <Icon
                    val="minimize"
                    onClick={() => setMinimizeContacts(true)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                )}
              </div>
            </div>
            <div
              style={{
                cursor: minimizeLists ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (minimizeLists) {
                  setMinimizeLists(false);
                }
              }}
              className={
                minimizeLists
                  ? 'companyWrapper__company__content__item minimize'
                  : 'companyWrapper__company__content__item'
              }
            >
              <CompanyInLists
                id={id}
                isMinimized={minimizeLists}
                onMinimizeChange={setMinimizeLists}
                isCompanyPage={isCompanyPage}
              />
              <div className="companyWrapper__company__content__item__block__header__icon">
                {minimizeLists ? (
                  <Icon
                    val="maximize"
                    onClick={() => setMinimizeLists(false)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                ) : (
                  <Icon
                    val="minimize"
                    onClick={() => setMinimizeLists(true)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                )}
              </div>
            </div>
          </div>

          <div className={activeTabId === 'fleet' ? styles.show : styles.hide}>
            <div
              className={
                minimizeFleet
                  ? 'companyWrapper__company__content__item minimize'
                  : 'companyWrapper__company__content__item'
              }
              style={{
                cursor: minimizeFleet ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (minimizeFleet) {
                  setMinimizeFleet(false);
                }
              }}
            >
              <FleetWidget
                prospectId={id}
                isMinimized={minimizeFleet}
                onMinimizeChange={setMinimizeFleet}
                isCompanyPage={isCompanyPage}
              />
              <div className="companyWrapper__company__content__item__block__header__icon">
                {minimizeFleet ? (
                  <Icon
                    val="maximize"
                    onClick={() => setMinimizeFleet(false)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                ) : (
                  <Icon
                    val="minimize"
                    onClick={() => setMinimizeFleet(true)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                )}
              </div>
            </div>
            <div
              className={
                minimizeFleetHistoric
                  ? 'companyWrapper__company__content__item minimize'
                  : 'companyWrapper__company__content__item'
              }
              style={{
                cursor: minimizeFleetHistoric ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (minimizeFleetHistoric) {
                  setMinimizeFleetHistoric(false);
                }
              }}
            >
              <FleetWidgetHistoric
                id={id}
                topOfPageRef={topOfPageRef}
                isMinimized={minimizeFleetHistoric}
                onMinimizeChange={setMinimizeFleetHistoric}
                isCompanyPage={isCompanyPage}
              />
              <div className="companyWrapper__company__content__item__block__header__icon">
                {minimizeFleetHistoric ? (
                  <Icon
                    val="maximize"
                    onClick={() => setMinimizeFleetHistoric(false)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                ) : (
                  <Icon
                    val="minimize"
                    onClick={() => setMinimizeFleetHistoric(true)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={
              activeTabId === 'organization' ? styles.show : styles.hide
            }
          >
            {company.koncern && company.koncern.id ? (
              <div
                className={
                  minimizeKoncern
                    ? 'companyWrapper__company__content__item minimize'
                    : 'companyWrapper__company__content__item'
                }
                style={{
                  cursor: minimizeKoncern ? 'pointer' : 'default',
                }}
                onClick={() => {
                  if (minimizeKoncern) {
                    setMinimizeKoncern(false);
                  }
                }}
              >
                <KoncernStructure
                  id={company.koncern?.id}
                  isMinimized={minimizeKoncern}
                  onMinimizeChange={setMinimizeKoncern}
                  isCompanyPage={isCompanyPage}
                />

                <div className="companyWrapper__company__content__item__block__header__icon">
                  {minimizeKoncern ? (
                    <Icon
                      val="maximize"
                      onClick={() => setMinimizeKoncern(false)}
                      class="companyWrapper__company__content__item__icon__cursor"
                    />
                  ) : (
                    <Icon
                      val="minimize"
                      onClick={() => setMinimizeKoncern(true)}
                      class="companyWrapper__company__content__item__icon__cursor"
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div
            className={activeTabId === 'history' ? styles.show : styles.hide}
          >
            <div
              className={
                minimizePlannedActivities
                  ? 'companyWrapper__company__content__item minimize'
                  : 'companyWrapper__company__content__item'
              }
              style={{
                cursor: minimizePlannedActivities ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (minimizePlannedActivities) {
                  setMinimizePlannedActivities(false);
                }
              }}
            >
              <Events
                onlyShowFlow={true}
                target={id}
                type="target"
                view="flow"
                isMinimized={minimizePlannedActivities}
                onMinimizeChange={setMinimizePlannedActivities}
                isCompanyPage={isCompanyPage}
              />
              <div className="companyWrapper__company__content__item__block__header__icon">
                {minimizePlannedActivities ? (
                  <Icon
                    val="maximize"
                    onClick={() => setMinimizePlannedActivities(false)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                ) : (
                  <Icon
                    val="minimize"
                    onClick={() => setMinimizePlannedActivities(true)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                )}
              </div>
            </div>
            <div
              className={
                minimizeActivity
                  ? 'companyWrapper__company__content__item minimize'
                  : 'companyWrapper__company__content__item'
              }
              style={{
                cursor: minimizeActivity ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (minimizeActivity) {
                  setMinimizeActivity(false);
                }
              }}
            >
              <Activities
                target={id}
                view="flow"
                isMinimized={minimizeActivity}
                onMinimizeChange={setMinimizeActivity}
                isCompanyPage={isCompanyPage}
                targetType={'company'}
              />
              <div className="companyWrapper__company__content__item__block__header__icon">
                {minimizeActivity ? (
                  <Icon
                    val="maximize"
                    onClick={() => setMinimizeActivity(false)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                ) : (
                  <Icon
                    val="minimize"
                    onClick={() => setMinimizeActivity(true)}
                    class="companyWrapper__company__content__item__icon__cursor"
                  />
                )}
              </div>
            </div>
          </div>

          {showCreateDeal && company && company.info && company.koncern ? (
            <CreateDeal
              close={async (dealCreated) => {
                if (dealCreated) {
                  setShowCreateDeal(false);
                  return await getDeals({ id: id });
                } else {
                  return setShowCreateDeal(false);
                }
              }}
              headline={
                tc.with +
                ' ' +
                tc.connection.toLowerCase() +
                ' ' +
                tc.to.toLowerCase() +
                ' ' +
                company.info.name
              }
              koncern={company.koncern.hasKoncern}
              prospects={[{ id: id, name: company.info.name }]}
              target={id}
            />
          ) : null}
          {showSaveToList ? (
            <SaveToList
              close={() => {
                setShowSaveToList(false);
              }}
              prospects={[id]}
            />
          ) : null}
          {showAddActivity ? (
            <AddActivityToProspectPopup
              close={() => {
                setShowAddActivity(false);
              }}
              prospectId={id}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    company: state.company,
    fleetAmount: state.fleet?.fleet?.total,
  };
};

export default connect(MapStateToProps)(Company);
