import LanguageContext from 'language-context';
import { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getMenuItems } from './utils';
import { NavLink, useLocation } from 'react-router-dom';
import Icon from 'components/icon';
import styles from './navigation.module.css';
import clsx from 'clsx';
import { setNavigationStatus, setShowSettings } from 'store/user/tasks';
import { getUpdateDate } from 'store/prospect/tasks';
/**
 *
 */
function NavigationNew({ user, ...props }) {
  const [updateDate, setUpdateDate] = useState(null);
  const tc = useContext(LanguageContext);
  const fetchingRef = useRef(false);

  useEffect(() => {
    const getData = async () => {
      fetchingRef.current = true;
      const data = await getUpdateDate();

      setUpdateDate(data);

      fetchingRef.current = false;
    };

    if (!fetchingRef.current) {
      getData();
    }
  }, []);

  const items = getMenuItems({ user, tc });
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.navlogo)}></div>;
      <nav className={clsx(styles.nav)}>
        {items.map((item) => {
          const { children, ...rest } = item;
          if (!children) {
            return <NavigationItem key={rest.id} isChild={false} {...rest} />;
          }
          return (
            <CollapsibleNavigationHeader
              paths={children.map((child) => child.path)}
              key={rest.id}
              {...rest}
            >
              {children?.map((child) => {
                return (
                  <NavigationItem key={child.id} isChild={true} {...child} />
                );
              })}
            </CollapsibleNavigationHeader>
          );
        })}
        <button
          onClick={() => {
            setShowSettings(true);
          }}
          className={clsx(styles.navlink, styles.settingsButton)}
        >
          <Icon val="settings" />
          <span>{tc.settings}</span>
        </button>

        {updateDate && (
          <div className={clsx(styles.updatedate)}>
            <p>Senaste datauppdatering: </p>
            <p>{updateDate.date}</p>
          </div>
        )}
        <div className={clsx(styles.mobileOnly)}>
          <button
            className={clsx(styles.footer)}
            onClick={() => {
              setNavigationStatus({ val: 'hidden' });
            }}
          >
            <Icon val="clear" />
          </button>
        </div>
      </nav>
    </div>
  );
}

function CollapsibleNavigationHeader({ name, paths, children }) {
  const location = useLocation();
  const childActive = paths.includes(location.pathname);
  return (
    <details className={clsx(styles.details)} open={childActive || false}>
      <summary>{name}</summary>
      {children}
    </details>
  );
}
function NavigationItem({ icon, path, name, isChild }) {
  const location = useLocation();
  const active = path === location.pathname;
  return (
    <li className={clsx(styles.listitem)}>
      <NavLink
        className={clsx(
          styles.navlink,
          active && styles.active,
          isChild && styles.childItem
        )}
        exact
        to={`${path}`}
      >
        <Icon val={icon} />
        {name}
      </NavLink>
    </li>
  );
}

const mapStateToProps = (state, props) => {
  return { user: state.user, ...props };
};
export default connect(mapStateToProps)(NavigationNew);
