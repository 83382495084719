import ActionButton from 'components/action_button';
import { getOptions } from 'components/pdf_archive_manage_files/lib';
import LanguageContext from 'language-context';
import { useContext } from 'react';

export default function ADRExamples() {
  const tc = useContext(LanguageContext);
  return (
    <>
      <ActionButton
        icon="download"
        label={`${tc.template} ${tc.envelope.toLowerCase()}`}
        href="/files/brevutskick/blank_envelope.pdf"
        type="regular"
      />
      <ActionButton
        icon="download"
        label={`${tc.template} ${tc.postcard.toLowerCase()} (ADR601)`}
        href="/files/brevutskick/blank_postcard.pdf"
        type="regular"
      />
      {[604, 605, 606, 607, 608, 609].map((ADR) => {
        const options = getOptions();
        return (
          <ActionButton
            key={ADR}
            icon="download"
            label={`${tc.template} ${options[
              ADR
            ].label.toLowerCase()} (ADR${ADR})`}
            href={`/files/brevutskick/ADR${ADR}.pdf`}
            type="regular"
          />
        );
      })}
    </>
  );
}
