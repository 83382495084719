import React from 'react';
import {
  Block,
  AccessTime,
  Add,
  Addchart,
  AdminPanelSettings,
  Analytics,
  Apartment,
  Apps,
  ArchiveOutlined,
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ArrowForwardRounded,
  ArrowRightRounded,
  Article,
  AttachMoney,
  AssignmentOutlined,
  Autorenew,
  BarChart,
  Bookmark,
  Celebration,
  Check,
  CheckBoxOutlineBlank,
  Clear,
  Comment,
  ContactMail,
  ContactSupport,
  CollectionsBookmark,
  ContentCopy,
  CorporateFare,
  Delete,
  Description,
  Done,
  KeyboardDoubleArrowDown,
  DriveEta,
  DynamicFeed,
  Edit,
  Equalizer,
  ExitToApp,
  Extension,
  FreeBreakfast,
  GetApp,
  Group,
  GroupOutlined,
  Help,
  Highlight,
  HistoryEdu,
  HistoryOutlined,
  Hub,
  InfoOutlined,
  InsertDriveFile,
  Insights,
  LabelImportant,
  Language,
  Launch,
  LegendToggle,
  Lens,
  List,
  LocationOn,
  LocalOffer,
  LocalShipping,
  Map,
  Mail,
  MarkChatRead,
  MergeType,
  Menu,
  MoreHoriz,
  NavigateBefore,
  NavigateNext,
  Notifications,
  NotificationsActive,
  NotInterested,
  PermContactCalendar,
  Person,
  PersonAddDisabled,
  PersonOutlined,
  Phone,
  PlaylistAdd,
  PlaylistAddCheck,
  Preview,
  Print,
  PriorityHigh,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Refresh,
  Remove,
  Reorder,
  Replay,
  Search,
  Send,
  Settings,
  Share,
  ShowChart,
  SkipNextRounded,
  Speed,
  Star,
  StarBorderOutlined,
  SwapHoriz,
  Summarize,
  TableChart,
  TextSnippet,
  ThumbDownAltRounded,
  ThumbUpAltRounded,
  Today,
  ToggleOff,
  ToggleOn,
  TrendingUp,
  Tune,
  Verified,
  ViewComfyOutlined,
  Visibility,
  VisibilityOff,
  VpnKey,
  WorkOutlineOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  ArrowBack,
  ArrowForward,
  PhoneMissed,
  Schedule,
  ContentCut,
  Flag,
  Gavel,
  Whatshot,
} from '@mui/icons-material';

/**
 * Returns an icon.
 * Can be rendered with onClick and with class from props.

 * @param props.class (optional) - string
 * @param props.onClick (optional) - func
 * @param props.val - string
 */
const Icon = (props) => {
  const onClick = typeof props.onClick === 'function' ? props.onClick : null;

  switch (props.val) {
    case 'actionTypes':
      return (
        <ViewComfyOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'activity':
      return (
        <HistoryOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'add':
      return (
        <Add className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'admin':
      return (
        <AdminPanelSettings
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'agile':
      return (
        <AssignmentOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'alerts':
      return (
        <InfoOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'analysis':
      return (
        <TrendingUp
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'archive':
      return (
        <ArchiveOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'arrowBack':
      return (
        <ArrowBack
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'analytics':
      return (
        <Analytics
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'arrowForward':
      return (
        <ArrowForward
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'article':
      return (
        <Article
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'available':
      return (
        <Check className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'celebrate':
      return (
        <Celebration
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'call':
      return (
        <Phone className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'car':
      return (
        <DriveEta
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'charts':
      return (
        <Equalizer
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'check':
      return (
        <Check className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'checkbox':
      return (
        <CheckBoxOutlineBlank
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'circle':
      return (
        <Lens
          className={props.class ? props.class : null}
          onClick={onClick}
          fill={props.color ? props.color : null}
        />
      );
    case 'clear':
      return (
        <Clear className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'combineWithData':
      return (
        <MergeType
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'comment':
      return (
        <Comment
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'company':
      return (
        <Apartment
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'complete':
      return (
        <Done className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'contact':
      return (
        <PermContactCalendar
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'contacts':
      return (
        <PermContactCalendar
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'contact_info':
      return (
        <ContactSupport
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'contractCustomerNewSign':
      return (
        <Gavel className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'contractCustomerReSign':
      return (
        <LocalOffer
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'copyContent':
      return (
        <ContentCopy
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'corporation':
      return (
        <CorporateFare
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'dashboard':
      return (
        <Apps className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'dates':
      return (
        <Today className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'did_call':
      return (
        <Phone className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'did_email':
      return (
        <Mail className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'did_lose_custom_reason':
      return (
        <Comment
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'did_lose_price':
      return (
        <AttachMoney
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'did_lose_product':
      return (
        <Extension
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'did_lose_unknown':
      return (
        <Help className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'did_lose_window':
      return (
        <NotInterested
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'did_mail':
      return (
        <Mail className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'did_meeting':
      return (
        <FreeBreakfast
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'did_post':
      return (
        <ContactMail
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'did_win_custom_reason':
      return (
        <Comment
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'doubleArrowDown':
      return (
        <KeyboardDoubleArrowDown
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    // case "doubleArrowLeft":
    //   return (
    //     <svg
    //       className={props.class ? "customSvg " + props.class : "customSvg"}
    //       onClick={onClick}
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 970.627 970.627"
    //     >
    //       <path
    //         d="M842.849,970.477c26.601,0,53.5-8.8,75.7-27c51.4-41.9,59.101-117.4,17.2-168.8l-234.6-288l237-290.9
    //                 c41.899-51.399,34.1-127-17.2-168.8c-51.4-41.899-127-34.1-168.8,17.2l-298.8,366.7c-36,44.1-36,107.399,0,151.6l296.4,363.9
    //                 C773.45,955.377,808.049,970.477,842.849,970.477z"
    //       />
    //       <path
    //         d="M328.85,926.276c23.7,29.101,58.301,44.2,93.101,44.2c26.6,0,53.5-8.8,75.7-27c51.399-41.9,59.1-117.4,17.199-168.8
    //                 l-234.599-288l237-290.9c41.9-51.399,34.1-127-17.2-168.8c-51.399-41.899-127-34.1-168.799,17.2l-298.9,366.7
    //                 c-36,44.1-36,107.5,0,151.6L328.85,926.276z"
    //     </svg>
    //   );
    // case 'doubleArrowRight':
    //     return (
    //         <svg className={props.class ? 'customSvg ' + props.class : 'customSvg'} onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 970.627 970.627">
    //             <path d="M49.777,943.627c22.3,18.101,49.1,27,75.7,27c34.8,0,69.4-15.1,93.1-44.199l298.7-366.7c36-44.101,36-107.401,0-151.601
    //                 l-296.4-363.899c-41.9-51.4-117.4-59.1-168.8-17.3c-51.4,41.9-59.1,117.5-17.3,168.9l234.7,288.101l-237,290.9
    //                 C-9.323,826.127-1.623,901.728,49.777,943.627z"/>
    //             <path d="M641.777,44.227c-41.801-51.4-117.4-59.1-168.801-17.3c-51.399,41.9-59.1,117.4-17.199,168.8l234.6,288.1l-237,290.901
    //                 c-41.9,51.399-34.1,127,17.2,168.8c22.3,18.1,49.1,27,75.7,27c34.8,0,69.399-15.1,93.1-44.2l298.7-366.7c36-44.1,36-107.4,0-151.6
    //                 L641.777,44.227z"/>
    //         </svg>
    //     );
    case 'download':
      return (
        <GetApp
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'draft':
      return (
        <Edit className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'edit':
      return (
        <Edit className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'eventsCalendar':
      return (
        <Today className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'exclamation':
      return (
        <PriorityHigh
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'excludeProspects':
      return (
        <PersonAddDisabled
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'file':
      return (
        <InsertDriveFile
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'fire':
      return (
        <Whatshot
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'flag':
      return (
        <Flag className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'flow':
      return (
        <Reorder
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'groups':
      return (
        <DynamicFeed
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'history':
      return (
        <HistoryEdu
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'hub':
      return (
        <Hub className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'infoOutlined':
      return (
        <InfoOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'insights':
      return (
        <Insights
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'inspection':
      return (
        <PlaylistAddCheck
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'koncern':
      return (
        <Language
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'label':
      return (
        <LabelImportant
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'leads':
      return (
        <Highlight
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'lift':
      return (
        <PhoneMissed
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'list':
      return (
        <List className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'lists':
      return (
        <List className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'load':
      return (
        <Refresh
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'login':
      return (
        <ExitToApp
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'logout':
      return (
        <ExitToApp
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'lost':
      return (
        <ThumbDownAltRounded
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'mail':
      return (
        <Mail className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'mailings':
      return (
        <LocalShipping
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'markChatRead':
      return (
        <MarkChatRead
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'maximize':
      return (
        <ArrowRightRounded
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'maximizeUp':
      return (
        <ArrowDropUpRounded
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'measureActivity':
      return (
        <Speed className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'meeting':
      return (
        <FreeBreakfast
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'meetingPlanned':
      return (
        <FreeBreakfast
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'menu':
      return (
        <Menu className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'minimize':
      return (
        <ArrowDropDownRounded
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'more':
      return (
        <MoreHoriz
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'move':
      return (
        <SwapHoriz
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'navigatePrevious':
      return (
        <NavigateBefore
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'navigateNext':
      return (
        <NavigateNext
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'newsItem':
      return (
        <TextSnippet
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'notAvailable':
      return (
        <Clear className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'notifications':
      return (
        <Notifications
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'notificationsActive':
      return (
        <NotificationsActive
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'offer':
      return (
        <Description
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'other':
      return (
        <Help className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'owner':
      return (
        <Person
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'orders':
      return (
        <PlaylistAdd
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'password':
      return (
        <VpnKey
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'pending':
      return (
        <AccessTime
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'person':
      return (
        <Person
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'phone':
      return (
        <Phone className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'postcard':
      return (
        <ContactMail
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'preselections':
      return (
        <StarBorderOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'preview':
      return (
        <Preview
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'price':
      return (
        <AttachMoney
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'print':
      return (
        <Print className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'prospect':
      return (
        <Person
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'prospectGroup':
      return (
        <CollectionsBookmark
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'prospectPreset':
      return (
        <Bookmark
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'prospectSearch':
      return (
        <Tune className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'radio':
      return (
        <RadioButtonUnchecked
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'radioChecked':
      return (
        <RadioButtonChecked
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'regions':
      return (
        <LocationOn
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'remove':
      return (
        <Delete
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'removeMUI':
      return (
        <Remove
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'reset':
      return (
        <Replay
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'save':
      return (
        <Check className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'script':
      return (
        <Description
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'search':
      return (
        <Search
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'send':
      return (
        <Send className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'service':
      return (
        <WorkOutlineOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'settings':
      return (
        <Settings
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'share':
      return (
        <Share className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'skip':
      return (
        <SkipNextRounded
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'star':
      return (
        <Star className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'subscription':
      return (
        <Autorenew
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    // case "summary":
    //   return (
    //     <ViewComfy
    //       className={props.class ? props.class : null}
    //       onClick={onClick}
    //     />
    //   );
    case 'team':
      return (
        <GroupOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'testride':
      return (
        <DriveEta
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'time':
      return (
        <AccessTime
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'toggleOff':
      return (
        <ToggleOff
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'toggleOn':
      return (
        <ToggleOn
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'user':
      return (
        <Person
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'userOutlined':
      return (
        <PersonOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'users':
      return (
        <Person
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'valuation':
      return (
        <AttachMoney
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'verified':
      return (
        <Verified
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'visibility':
      return (
        <Visibility
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'visibilityOff':
      return (
        <VisibilityOff
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'visit':
      return (
        <Group className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'will_call':
      return (
        <Phone className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'will_mail':
      return (
        <Mail className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'will_meeting':
      return (
        <FreeBreakfast
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'will_post':
      return (
        <ContactMail
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'won':
      return (
        <ThumbUpAltRounded
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'dealsWon':
      return (
        <ThumbUpAltRounded
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'granularity_inc':
      return (
        <ZoomInOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'granularity_dec':
      return (
        <ZoomOutOutlined
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'launch':
      return (
        <Launch
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'legend_toggle_on':
      return (
        <Visibility
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'legend_toggle_off':
      return (
        <VisibilityOff
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'line_chart':
      return (
        <ShowChart
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'bar_chart':
      return (
        <BarChart
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'table_chart':
      return (
        <TableChart
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'add_chart':
      return (
        <Addchart
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'legend_toggle':
      return (
        <LegendToggle
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'map_chart':
      return (
        <Map className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'schedule':
      return (
        <Schedule
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'contentcut':
      return (
        <ContentCut
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    case 'block':
      return (
        <Block className={props.class ? props.class : null} onClick={onClick} />
      );
    case 'summarize':
      return (
        <Summarize
          className={props.class ? props.class : null}
          onClick={onClick}
        />
      );
    default:
      return props.val || null;
  }
};

export default Icon;
