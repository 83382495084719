import { store } from 'store';
import { pdfActionTypes } from './actions';
import axios from 'axios';
import { request } from 'helpers';
import * as text from 'text-content';
import { showFlashMessage } from '../flash_messages/tasks';

// export const convertPdfUrlToBlob = async (url) => {
//   try {
//     if (!url) {
//       console.error('Missing params in convertPdfUrlToBlob');
//     }

//     const data: any = await axios({
//       responseType: 'arraybuffer',
//       headers: {
//         'Content-Type': 'application/json',
//         Accept: 'application/pdf',
//       },
//       method: 'get',
//       url: url,
//     });

//     if (data instanceof Error) {
//       return null;
//     }

//     if (data?.data) {
//       return new Blob([data.data], { type: 'application/pdf' });
//     }
//   } catch (err) {
//     console.error('Error in convertPdfUrlToBlob', err);
//     return null;
//   }
// };

/**
 * Get users saved pdf files.
 *
 * @param payload.type
 */
export const getUserPdfs = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    let data = await request({
      data: {
        type: payload.type,
      },
      method: 'get',
      url: '/pdf/userPdf/',
    });

    if (data instanceof Error) {
      console.error('Error in getUserPdfs', data);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    if (Array.isArray(data) && data.length) {
      data = data.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    setPdfs(data);
    return data;
  } catch (err) {
    console.error('Error in getUserPdfs', err);
    showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

export const removeUserPdf = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload?.id || !payload?.s3Key) {
      console.error('Missing params in removeUserPdf', payload);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    await request({
      data: payload,
      method: 'delete',
      url: '/pdf/userPdf/',
    });

    showFlashMessage(tc.fileWasRemoved, 'success');
  } catch (err) {
    console.error('Error in removeUserPdf', err);
    showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

export const setPdfs = (payload) => {
  store.dispatch({ type: pdfActionTypes.PDF_SET_USER_PDFS, payload: payload });
};

/**
 * Share a user pdf object to colleagues.
 *
 * @param payload.id
 * @param payload.users
 */
export const shareUserPdf = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload?.id || !payload?.users || !Array.isArray(payload?.users)) {
      console.error('Missing params in shareUserPdf', payload);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    const data = await request({
      data: {
        id: payload.id,
        users: payload.users,
      },
      method: 'post',
      url: '/pdf/userPdf/share',
    });

    if (data instanceof Error) {
      console.error('Error in shareUserPdf', data);
      return showFlashMessage(tc.genericFailMessage, 'fail');
    }

    return showFlashMessage(tc.fileHasBeenShared, 'success');
  } catch (err) {
    console.error('Error in shareUserPdf', err);
    showFlashMessage(tc.genericFailMessage, 'fail');
  }
};

export const uploadUserPdf = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (
      !payload?.pdf ||
      !payload.name ||
      !payload?.type ||
      !payload?.ADR ||
      !payload.Typ
    ) {
      console.error('Missing params in uploadUserPdf', payload);
      return showFlashMessage(tc.couldNotUploadFile, 'fail');
    }

    if (payload.pdf.size > 5000000) {
      console.error('Too large file size in uploadUserPdf');
      return showFlashMessage(tc.fileIsTooBig5Mb, 'fail');
    }

    let formData = new FormData();
    formData.append('user-pdf', payload.pdf);
    formData.append('name', payload.name);
    formData.append('type', payload.type);
    formData.append('ADR', payload.ADR);
    formData.append('Typ', payload.Typ);

    // Keeping this if needed: to log out content of formData we need to call entries.
    // for (let key of formData.entries()) {
    //   console.log(key[0] + ", " + key[1]);
    // }

    const data = await axios({
      headers: {
        'Content-Type': undefined, // Use undefined to save byte array overhead.
      },
      data: formData,
      method: 'post',
      url: '/pdf/userPdf/',
      withCredentials: true,
    });

    showFlashMessage(tc.fileWasSaved, 'success');
    return data.data;
  } catch (err) {
    console.error('Error in uploadUserPdf', err);
    showFlashMessage(tc.couldNotUploadFile, 'fail');
  }
};
