import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import {
  resetMailings,
  setActiveOrderStage,
  setActiveOrderType,
  setListToOrder,
} from 'store/orders/tasks';
import ActionButton from 'components/action_button';
import Collapsible from 'components/collapsible';
import Highlight from 'components/highlight';
import Menu from 'components/menu';
import OrdersOrderMailings from './orders_order_mailings';
import OrdersOrderManagement from './orders_order_management';
import OrdersOrderWaystar from './orders_order_waystar';
import OrdersOrderName from './orders_order_name';
import OrdersOrderPhone from './orders_order_phone';
import OrdersOrderHubspot from './orders_order_hubspot';
import WidgetHeader from 'components/widget_header';
import { getListsProspectInformation } from 'store/lists/tasks';
import { miscHelper } from 'helpers';
import OrderMailings from './order_mailings/order_mailings';
import ADRExamples from 'components/ADR_examples';

const OrdersOrder = (state) => {
  const tc = useContext(LanguageContext);

  const [prospectInformation, setProspectInformation] = useState(null);
  const [error, setError] = useState(false);

  const listNew = {
    ...state?.orders?.list,
    prospectInformation,
  };

  useEffect(() => {
    const getData = async () => {
      const [info] = (
        await getListsProspectInformation({
          listIds: [state?.orders?.list?.id],
        })
      ).prospectInformation;

      if (info?.data) {
        const { error: err, ...rest } = info.data;
        setError(err ? true : false);
        setProspectInformation(rest);
      }
    };

    if (state?.orders?.list?.id) {
      setError(false);
      getData();
    }
  }, [state?.orders?.list?.id]);

  const _renderMailingsInstructions = () => {
    return (
      <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section">
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__title">
          <WidgetHeader headline={tc.orderMailingsInstructionsInfoHeader} />
        </div>
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content">
          <p>
            {`${tc.orderMailingsInstructionsInfo1} ${tc.orderMailingsInstructionsInfoPdfValidation}`}
          </p>
          <p>{tc.orderMailingsInstructionsInfo2}</p>
          <p>{tc.orderMailingsInstructionsInfo3}</p>
          <p>{tc.orderMailingsInstructionsInfo4}</p>
          <p>
            {tc.input}:<br />
            <Highlight text={tc.orderMailingsInstructionsParamsInput} />
          </p>
          <p>
            {tc.output}:<br />
            <Highlight text={tc.orderMailingsInstructionsParamsOutput} />
          </p>
        </div>
      </div>
    );
  };

  const _renderGeneralOrderInfo = () => {
    return (
      <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section">
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__title">
          <WidgetHeader headline={tc.orderGeneralHeadline} />
        </div>
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content">
          <p>{tc.orderGeneralContent1}</p>
          <p>{tc.orderGeneralContent2}</p>
          <p>{tc.orderGeneralContent3}</p>
          <p>{tc.orderGeneralContent4}</p>
        </div>
      </div>
    );
  };

  const _renderListOrderHistory = () => {
    let availableOrderData;
    let orderHistory;
    let listInfo;

    // Available order data.
    let companyNames =
      state.orders.list.availableOrderData &&
      state.orders.list.availableOrderData.name &&
      state.orders.list.availableOrderData.name.company
        ? state.orders.list.availableOrderData.name.company
        : 0;
    let prospectNames =
      state.orders.list.availableOrderData &&
      state.orders.list.availableOrderData.name &&
      state.orders.list.availableOrderData.name.prospects
        ? state.orders.list.availableOrderData.name.prospects
        : 0;
    let companyPhoneNumbers =
      state.orders.list.availableOrderData &&
      state.orders.list.availableOrderData.phone &&
      state.orders.list.availableOrderData.phone.company
        ? state.orders.list.availableOrderData.phone.company
        : 0;
    let prospectPhoneNumbers =
      state.orders.list.availableOrderData &&
      state.orders.list.availableOrderData.phone &&
      state.orders.list.availableOrderData.phone.prospects
        ? state.orders.list.availableOrderData.phone.prospects
        : 0;
    let managementData =
      state.orders.list.availableOrderData &&
      state.orders.list.availableOrderData.management &&
      state.orders.list.availableOrderData.management.amount
        ? state.orders.list.availableOrderData.management.amount
        : 0;

    if (
      companyNames > 0 ||
      prospectNames > 0 ||
      companyPhoneNumbers > 0 ||
      prospectPhoneNumbers > 0 ||
      managementData > 0
    ) {
      availableOrderData = (
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section">
          <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__title">
            <WidgetHeader headline={tc.availableOrderData} />
          </div>
          {companyNames > 0 || prospectNames > 0 ? (
            <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content">
              <p>
                <span className="label">{tc.name}</span>
              </p>
              <p>
                {`${tc.thereIs}`}
                {prospectNames > 0 ? (
                  <strong className="margin">{prospectNames}</strong>
                ) : (
                  <span className="margin">{prospectNames}</span>
                )}
                {`${tc.availableProspectNames.toLowerCase()} ${tc.and.toLowerCase()}`}
                {companyNames > 0 ? (
                  <strong className="margin">{companyNames}</strong>
                ) : (
                  <span className="margin">{companyNames}</span>
                )}
                {`${tc.availableCompanyNames.toLowerCase()}.`}
              </p>
            </div>
          ) : null}
          {companyPhoneNumbers > 0 || prospectPhoneNumbers > 0 ? (
            <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content">
              <p>
                <span className="label">{tc.phoneNumbers}</span>
              </p>
              <p>
                {`${tc.thereIs}`}
                {prospectPhoneNumbers > 0 ? (
                  <strong className="margin">{prospectPhoneNumbers}</strong>
                ) : (
                  <span className="margin">{prospectPhoneNumbers}</span>
                )}
                {`${tc.availableProspectPhoneNumbers.toLowerCase()} ${tc.and.toLowerCase()}`}
                {companyPhoneNumbers > 0 ? (
                  <strong className="margin">{companyPhoneNumbers}</strong>
                ) : (
                  <span className="margin">{companyPhoneNumbers}</span>
                )}
                {`${tc.availableCompanyPhoneNumbers.toLowerCase()}.`}
              </p>
            </div>
          ) : null}
          {managementData > 0 ? (
            <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content">
              <p>
                <span className="label">{tc.management}</span>
              </p>
              <p>
                {tc.thereIs}
                <strong className="margin">{managementData}</strong>
                {tc.availableManagementData.toLowerCase()}
              </p>
            </div>
          ) : null}
        </div>
      );
    }

    const { orderHistory: listOrderHistory } = state.orders.list;
    // Order history.
    let managementIsDelivered = !!listOrderHistory?.company?.isDelivered;
    let managementIsOld = !!(
      managementIsDelivered && !orderHistory?.company?.isAvailable
    );
    let managementIsPending = !!listOrderHistory?.company?.isPending;
    let mailingsIsDelivered = !!listOrderHistory?.mailings?.isDelivered;
    let mailingsIsOld = !!(
      mailingsIsDelivered && !listOrderHistory?.mailings?.isAvailable
    );
    let mailingsIsPending = !!listOrderHistory?.mailings?.isPending;
    let nameIsDelivered = !!listOrderHistory?.name?.isDelivered;
    let nameIsOld = nameIsDelivered && !listOrderHistory?.name?.isAvailable;
    let nameIsPending = !!listOrderHistory?.name?.isPending;
    let phoneIsDelivered = !!listOrderHistory?.phone?.isDelivered;
    let phoneIsOld = phoneIsDelivered && !listOrderHistory?.phone?.isAvailable;
    let phoneIsPending = !!listOrderHistory?.phone?.isPending;
    let hubspotIsDelivered = !!listOrderHistory?.hubspot?.isDelivered;
    let hubspotIsPending = !!listOrderHistory?.hubspot?.isPending;

    if (
      managementIsDelivered ||
      managementIsPending ||
      mailingsIsDelivered ||
      mailingsIsPending ||
      phoneIsDelivered ||
      phoneIsPending ||
      nameIsDelivered ||
      nameIsPending ||
      hubspotIsDelivered ||
      hubspotIsPending
    ) {
      orderHistory = (
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section">
          <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__title">
            <WidgetHeader headline={tc.orderHistory} />
          </div>
          <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content">
            {nameIsDelivered || nameIsPending ? (
              <p className="noMargin">
                <span className="label">{tc.name}:</span>
                {nameIsDelivered ? <span>{tc.hasBeenDelivered}</span> : null}
                {nameIsDelivered && nameIsOld ? ' - ' : null}
                {nameIsOld ? <span>{tc.orderDataIsOld}</span> : null}
                {(nameIsDelivered || nameIsOld) && nameIsPending ? ' - ' : null}
                {nameIsPending ? <span>{tc.orderIsPending}</span> : null}
              </p>
            ) : null}
            {phoneIsDelivered || phoneIsPending ? (
              <p className="noMargin">
                <span className="label">{tc.phoneNumbers}:</span>
                {phoneIsDelivered ? <span>{tc.hasBeenDelivered}</span> : null}
                {phoneIsDelivered && phoneIsOld ? ' - ' : null}
                {phoneIsOld ? <span>{tc.orderDataIsOld}</span> : null}
                {(phoneIsDelivered || phoneIsOld) && phoneIsPending
                  ? ' - '
                  : null}
                {phoneIsPending ? <span>{tc.orderIsPending}</span> : null}
              </p>
            ) : null}
            {mailingsIsDelivered || mailingsIsPending ? (
              <p className="noMargin">
                <span className="label">{tc.mailings}:</span>
                {mailingsIsDelivered ? (
                  <span>{tc.hasBeenDelivered}</span>
                ) : null}
                {mailingsIsDelivered && mailingsIsOld ? ' - ' : null}
                {mailingsIsOld ? <span>{tc.orderDataIsOld}</span> : null}
                {(mailingsIsDelivered || mailingsIsOld) && mailingsIsPending
                  ? ' - '
                  : null}
                {mailingsIsPending ? <span>{tc.orderIsPending}</span> : null}
              </p>
            ) : null}
            {managementIsDelivered || managementIsPending ? (
              <p className="noMargin">
                <span className="label">{tc.management}:</span>
                {managementIsDelivered ? (
                  <span>{tc.hasBeenDelivered}</span>
                ) : null}
                {managementIsDelivered && managementIsOld ? ' - ' : null}
                {managementIsOld ? <span>{tc.orderDataIsOld}</span> : null}
                {(managementIsDelivered || managementIsOld) &&
                managementIsPending
                  ? ' - '
                  : null}
                {managementIsPending ? <span>{tc.orderIsPending}</span> : null}
              </p>
            ) : null}
            {hubspotIsDelivered || hubspotIsPending ? (
              <p className="noMargin">
                <span className="label">{tc.hubspot}:</span>
                {hubspotIsDelivered ? <span>{tc.hasBeenDelivered}</span> : null}
                {hubspotIsPending ? (
                  <span>
                    {hubspotIsDelivered ? ' - ' : null}
                    {tc.orderIsPending}
                  </span>
                ) : null}
              </p>
            ) : null}
          </div>
        </div>
      );
    }

    if (prospectInformation) {
      listInfo = (
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section">
          <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__title">
            <WidgetHeader headline={state.orders.list.name} />
          </div>
          <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content">
            <p>
              {`${tc.listContains} ${prospectInformation.companies} ` +
                `${tc.companies.toLowerCase()}, ${
                  prospectInformation.privatePersons
                } ${tc.privatePersons.toLowerCase()} ` +
                `${tc.and.toLowerCase()} ${String(
                  prospectInformation.vehicles
                ).toLowerCase()} ${tc.vehicles.toLowerCase()}.`}
            </p>
            <p>{`${tc.companyDataIsFree} ${
              prospectInformation.noMarketing
                ? prospectInformation.noMarketing
                : 0
            } ${tc.haveNoMarketing.toLowerCase()}`}</p>
            {state.orders.activeOrderType === 'mailings' && error ? (
              <p>{tc.mailingsVehicleLimitNotice}</p>
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <>
        {listInfo}
        {availableOrderData}
        {orderHistory}
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section">
          <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content">
            <ActionButton
              icon="navigatePrevious"
              label={tc.removeSelectedListToOrder}
              onClick={() => {
                setActiveOrderStage('selectList');
                resetMailings();
                setListToOrder(null);
              }}
              type="regular"
            />
          </div>
        </div>
      </>
    );
  };

  const _renderOrderInformation = () => {
    let orderInformation;
    if (state.orders.list && state.orders.activeOrderType) {
      switch (state.orders.activeOrderType) {
        case 'mailings':
          orderInformation = (
            <>
              <OrderMailings list={listNew} mailingsVehicleError={error} />
              {/* <OrdersOrderMailings
                list={listNew}
                setMode={state.props.setMode}
                mailingsVehicleError={error}
              /> */}
            </>
          );
          break;
        case 'management':
          orderInformation = (
            <OrdersOrderManagement
              list={listNew}
              setMode={state.props.setMode}
            />
          );
          break;
        case 'name':
          orderInformation = (
            <OrdersOrderName list={listNew} setMode={state.props.setMode} />
          );
          break;
        case 'phone':
          orderInformation = (
            <OrdersOrderPhone list={listNew} setMode={state.props.setMode} />
          );
          break;
        case 'waystar':
          orderInformation = (
            <OrdersOrderWaystar list={listNew} setMode={state.props.setMode} />
          );
          break;
        case 'hubspot':
          orderInformation = (
            <OrdersOrderHubspot list={listNew} setMode={state.props.setMode} />
          );
          break;
        default:
          return null;
      }

      return (
        <>
          <div className="ordersWrapper__orders__content__bottom__orderInformation">
            <div className="ordersWrapper__orders__content__bottom__orderInformation__left">
              {_renderListOrderHistory()}
              {state.orders.activeOrderType === 'mailings' && (
                <Collapsible
                  minimized={true}
                  content={
                    <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content__flexHolder">
                      <ADRExamples />
                    </div>
                  }
                  header={tc.templates}
                />
              )}
              {state.orders.activeOrderType === 'mailings' ? (
                <div className="marginBottom">
                  <Collapsible
                    content={_renderMailingsInstructions()}
                    header={tc.orderMailingsInstructions}
                    minimized={true}
                  />
                </div>
              ) : null}

              <Collapsible
                content={
                  <>
                    {_renderOrderInformationForOrderType()}
                    {_renderGeneralOrderInfo()}
                  </>
                }
                header={tc.orderInformation}
                minimized={true}
              />
            </div>
            <div className="ordersWrapper__orders__content__bottom__orderInformation__right">
              {orderInformation}
            </div>
          </div>
        </>
      );
    }
  };

  const _renderOrderInformationForOrderType = () => {
    return (
      <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section">
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__title">
          <WidgetHeader
            headline={
              state.orders.activeOrderType === 'name' ||
              state.orders.activeOrderType === 'phone'
                ? tc.nameAndPhoneOrder
                : state.orders.activeOrderType === 'mailings'
                ? tc.mailingsOrder
                : tc.managementOrder
            }
          />
        </div>
        <div className="ordersWrapper__orders__content__bottom__orderInformation__left__section__content">
          {state.orders.activeOrderType === 'name' ||
          state.orders.activeOrderType === 'phone' ? (
            <>
              <p>{tc.orderNameInfo1}</p>
              <p>{tc.orderNameInfo2}</p>
            </>
          ) : state.orders.activeOrderType === 'mailings' ? (
            <>
              <p>{tc.orderMailingsInfo1}</p>
              <p>{tc.orderMailingsInfo2}</p>
            </>
          ) : (
            <>
              <p>{tc.orderManagementInfo1}</p>
              <p>{tc.orderManagementInfo2}</p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="ordersWrapper__orders__content">
      <div className="ordersWrapper__orders__content__bottom">
        <div className="ordersWrapper__orders__content__bottom__menu">
          {state.orders.activeOrderStage === 'listOrder' ? (
            <Menu
              centered={true}
              items={[
                {
                  active: state.orders.activeOrderType === 'name',
                  disabled: !state.orders.list,
                  icon: 'person',
                  label: tc.orderName,
                  labelSub: tc.orderNameHeadlineSub,
                  onClick: () => {
                    setActiveOrderType('name');
                  },
                  type: 'button',
                },
                state.orders?.list?.orderHistory?.name?.isAvailable
                  ? {
                      active: state.orders.activeOrderType === 'phone',
                      disabled: !state.orders.list,
                      icon: 'phone',
                      label: tc.orderPhone,
                      labelSub: tc.orderPhoneHeadlineSub,
                      onClick: () => {
                        setActiveOrderType('phone');
                      },
                      type: 'button',
                    }
                  : null,
                {
                  active: state.orders.activeOrderType === 'mailings',
                  disabled: !state.orders.list,
                  icon: 'mail',
                  label: tc.orderMailings,
                  labelSub: tc.orderMailingsHeadlineSub,
                  onClick: () => {
                    setActiveOrderType('mailings');
                  },
                  type: 'button',
                },
                {
                  active: state.orders.activeOrderType === 'management',
                  disabled: !state.orders.list,
                  icon: 'company',
                  label: tc.orderManagement,
                  labelSub: tc.orderManagementHeadlineSub,
                  onClick: () => {
                    setActiveOrderType('management');
                  },
                  type: 'button',
                },
                {
                  active: state.orders.activeOrderType === 'waystar',
                  disabled:
                    !state.orders.list ||
                    (!state.orders.list.criterias &&
                      !state.orders.list.meta?.criterias),
                  icon: 'flag',
                  label: tc.orderWaystar,
                  labelSub: tc.waystarFlag,
                  onClick: () => {
                    setActiveOrderType('waystar');
                  },
                  type: 'button',
                },
                miscHelper.hasHubspotIntegrationPermission(state.user)
                  ? {
                      active: state.orders.activeOrderType === 'hubspot',
                      disabled: !state.orders.list,
                      icon: 'flag',
                      label: tc.hubspotOrderToHubspot,
                      labelSub: tc.hubspotExportData,
                      onClick: () => {
                        setActiveOrderType('hubspot');
                      },
                      type: 'button',
                    }
                  : null,
              ]}
              type="large"
              skipSort={true}
            />
          ) : null}
        </div>
        {_renderOrderInformation()}
      </div>
    </div>
  );
};

const MapStateToProps = (state, props) => {
  return {
    lists: state.lists,
    orders: state.orders,
    props: props,
    user: state.user,
  };
};

export default connect(MapStateToProps)(OrdersOrder);
