import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LanguageContext from 'language-context';
import { miscHelper, prospectHelper } from 'helpers';
import Icon from 'components/icon';
import PreviewSection from './preview_section';
import PreviewSectionRow from './preview_section_row';
import WidgetHeader from 'components/widget_header';

/**
 * Render preview component - display selected filters/criterias etc.
 * Universal and can be used anywhere, but built with Prospektera in mind.
 * Icons string array should match with sections headers (these values also have to exist in Icon component).
 *
 */
const Preview = (state) => {
  const { prospect } = state;
  const data = useMemo(() => {
    return prospectHelper.getPreviewObject(prospect.data);
  }, [prospect.data]);
  const icons = []
    .concat(data?.car ? ['car'] : [])
    .concat(data?.prospect ? ['prospect'] : [])
    .concat(data?.regions ? ['regions'] : [])
    .concat(data?.combineWithData ? ['combineWithData'] : []);
  const [minimize, setMinimize] = useState(true);
  const [selectedSection, setSelectedSection] = useState('');
  const previewRef = useRef(null);
  const tc = useContext(LanguageContext);
  const type = 'prospectSearch';
  const getHeadline = () => {
    switch (type) {
      case 'prospectSearch':
        return tc.previewheadlineProspectSearch;
      case 'activity':
        return tc.previewheadlineActivity;
      default:
        return '';
    }
  };
  const getHeadlineSub = () => {
    switch (type) {
      case 'prospectSearch':
        return tc.previewheadlineSubProspectSearch;
      case 'activity':
        return tc.previewheadlineSubActivity;
      default:
        return '';
    }
  };
  const headline = getHeadline();
  const headlineSub = getHeadlineSub();

  useEffect(() => {
    /**
     * When clicking outside preview, close it.
     */
    const _setMinimize = (e) => {
      if (miscHelper.clickUnmount(e, previewRef, false)) {
        setMinimize(true);
      }
    };

    window.addEventListener('mousedown', _setMinimize);
    return () => window.removeEventListener('mousedown', _setMinimize);
  }, []);

  useEffect(() => {
    if (state.preview.icons?.length) {
      // On first render, set the first section as active.
      setSelectedSection(state.preview.icons[0]);
      _scrollToSection('#preview-' + state.preview.icons[0]);
    }
  }, [state.preview.icons]);

  const _renderIcons = () => {
    return icons.map((val) => {
      return (
        <Icon
          class={selectedSection === val ? 'active' : null}
          key={'preview-' + val}
          onClick={() => {
            if (minimize) {
              return;
            }

            _scrollToSection('#preview-' + val);
            setSelectedSection(val);
          }}
          val={val}
        />
      );
    });
  };

  const _renderSections = () => {
    /**
     * Build sections.
     */
    const buildSections = (obj, level = 0) => {
      return Object.keys(obj).map((prop) => {
        const isRow =
          Array.isArray(obj[prop]) ||
          (obj[prop].hasOwnProperty('from') && obj[prop].hasOwnProperty('to'));

        if (level === 0) {
          // Main section.
          return (
            <PreviewSection
              adjustMarginBottom={!minimize}
              key={prop + level}
              name={prop}
              renderContent={() => {
                return buildSections(obj[prop], level + 1);
              }}
              subSection={false}
            />
          );
        } else if (level === 1 && typeof obj[prop] === 'object' && !isRow) {
          if (obj.excludeListsInverted) {
          }
          // Nested sub section object, such as prospect.company.
          return (
            <PreviewSection
              adjustMarginBottom={!minimize}
              key={prop + level}
              name={prop}
              renderContent={() => {
                return buildSections(obj[prop], level + 1);
              }}
              subSection={true}
            />
          );
        } else if (isRow) {
          // Insert row.
          return <PreviewSectionRow key={prop + level} name={prop} obj={obj} />;
        } else {
          // Dont display anything for other level/value combinations (should not exist).
          return buildSections(obj[prop], level + 1);
        }
      });
    };

    return buildSections(data);
  };

  const _scrollToSection = (id) => {
    const el = document.querySelector(id);

    if (!el) {
      return;
    }

    document
      .querySelectorAll('.previewWrapper__preview__right__content')[0]
      .scroll(0, el.offsetTop);
  };

  return (
    <div className="previewWrapper" ref={previewRef}>
      <div className="previewWrapper__preview">
        {minimize ? (
          <div className="previewWrapper__preview__left">
            <div
              className="previewWrapper__preview__left__iconHolder"
              onClick={() => {
                setMinimize(!minimize);
              }}
            >
              <Icon val="navigatePrevious" />
            </div>
          </div>
        ) : null}
        <div
          className={
            minimize
              ? 'previewWrapper__preview__right minimize'
              : 'previewWrapper__preview__right'
          }
        >
          {!minimize ? (
            <div className="previewWrapper__preview__right__header">
              <div className="previewWrapper__preview__right__header__top">
                <div className="previewWrapper__preview__right__header__top__left">
                  <WidgetHeader headline={headline} headlineSub={headlineSub} />
                </div>
                <div
                  className="previewWrapper__preview__right__header__top__right"
                  onClick={() => {
                    setMinimize(true);
                  }}
                >
                  <Icon val="clear" />
                </div>
              </div>
              <div className="previewWrapper__preview__right__header__bottom">
                {!minimize && icons ? _renderIcons() : null}
              </div>
            </div>
          ) : null}
          <div className="previewWrapper__preview__right__content">
            {data ? _renderSections() : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    prospect: state.prospect,
    preview: state.preview,
  };
};

export default connect(MapStateToProps)(Preview);
