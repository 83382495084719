import { store } from 'store';
import { fleetActionTypes } from './actions';
import { chartHelper, prospectHelper, request } from 'helpers';
import personHelper from 'shared_helpers/person_helper';
import companyHelper from 'shared_helpers/company_helper';
import { debounce } from 'debounce';
import * as text from 'text-content';

/**
 * Helper.
 * Return a data object for chart.js based on a specific data format.
 *
 * @param data
 * @param val
 * @param obj
 * @param title
 */
const _generateDataObjectForChartJS1 = (data, val, obj, title) => {
  if (data[val][0].length && data[val][1].length) {
    data[val][0] = data[val][0].map((num, i) => {
      if (num.length > 19) {
        // Temporary and ugly solution but labels get too long in chart rendering.
        num = num.substring(0, 16) + '...';
      }

      // Add value to string.
      num = `${num} (${data[val][1][i]})`;
      return num;
    });

    obj[val] = {
      labels: data[val][0].slice(1),
      datasets: [
        {
          label: title,
          labels: data[val][0].slice(1),
          data: data[val][1].slice(1),
          // fill: false,
          backgroundColor: chartHelper.generateChartColors(
            data[val][1].slice(1).length,
            false,
            false,
            false
          ),
          borderWidth: 1,
          borderColor: 'white',
        },
      ],
    };
  }

  return obj;
};

/**
 * Helper.
 * Return a data object for chart.js based on a specific data format.
 * (Backend returns data in two different format, so this differs from _adjustForChartJS1.)
 *
 * @param data
 * @param val
 * @param obj
 * @param title
 */
const _generateDataObjectForChartJS2 = (data, val, obj, title) => {
  let newData: any = [];
  let newLabels: any = [];

  data[val].forEach((num) => {
    if (num.length) {
      if (num[0].length > 19) {
        // Temporary and ugly solution but labels get too long in chart rendering.
        num[0] = num[0].substring(0, 16) + '...';
      }

      num[0] = `${num[0]} (${num[1]})`;
      newData = newData.concat([num[1]]);
      newLabels = newLabels.concat([num[0]]);
    }
  });

  if (newData.length) {
    obj[val] = {
      labels: newLabels,
      datasets: [
        {
          label: title,
          labels: newLabels,
          data: newData,
          // fill: false,
          backgroundColor: chartHelper.generateChartColors(
            newData.length,
            false,
            false,
            false
          ),
          borderWidth: 1,
          borderColor: 'white',
        },
      ],
    };
  }

  return obj;
};

/**
 * Fetch fleet.
 *
 * @param payload.historic - bool - If we want historic fleet.
 * @param payload.koncern - bool - If we want fleet for koncern.
 * @param payload.sort - object - {order: 'desc' | 'asc', orderBy: 'column_name'}
 * @param payload.query - string - Search query.
 * @param payload.page - number - Page number.
 * @param payload.prospectId - string - TS user id to fetch data for.
 * @param payload.rowsPerPage - number - Rows per page.
 */
export const getFleet = async (payload) => {
  try {
    if (!payload || (payload && !payload.prospectId)) {
      return console.error('Missing params in getFleet', payload);
    } else {
      payload.prospectId = payload.prospectId.toString();
    }

    if (payload.historic) {
      store.dispatch({
        type: fleetActionTypes.SET_FLEET_HISTORIC_LOADING,
        payload: true,
      });
    } else {
      store.dispatch({
        type: fleetActionTypes.SET_FLEET_LOADING,
        payload: true,
      });
    }

    const data = await request({
      data: {
        historic: payload.historic ? 1 : 0,
        koncern: payload.koncern ? 1 : 0,
        rowsPerPage: payload.rowsPerPage ? payload.rowsPerPage : 25,
        sort: payload.sort
          ? payload.sort
          : {
              order: 'asc',
              orderBy: null,
            },
        page: payload.page || 0,
        query: payload.query && payload.query.length ? payload.query : null,
        activeFilters: payload.activeFilters ? payload.activeFilters : null,
      },
      method: 'get',
      url: '/fleet/getFleet/paginated/' + payload.prospectId,
    });

    if (!data || data instanceof Error || !data.results) {
      if (payload.historic) {
        return store.dispatch({
          type: fleetActionTypes.SET_FLEET_HISTORIC,
          payload: {},
        });
      } else {
        return store.dispatch({
          type: fleetActionTypes.SET_FLEET,
          payload: {},
        });
      }
    }

    const result = {
      amount: data.amount,
      data: data.results,
      // query: payload.query,
      target: payload.prospectId,
      total: data.total,
    };

    if (payload.historic) {
      return store.dispatch({
        type: fleetActionTypes.SET_FLEET_HISTORIC,
        payload: result,
      });
    } else {
      return store.dispatch({
        type: fleetActionTypes.SET_FLEET,
        payload: result,
      });
    }
  } catch (err) {
    return console.error('Error in getFleet', err);
  }
};

export const getColumnOptions = async ({
  targetId,
  type,
  columns,
  useProspectData,
  listCriterias,
  listId,
  activeFilters,
  activeSearch,
}) => {
  try {
    let prospectData, prospectDataLocalStorage, query;
    if (!activeFilters) activeFilters = store.getState().table.activeFilters;
    if (useProspectData) {
      prospectDataLocalStorage = localStorage.getItem(
        'bilprospekt-prospect-data'
      );

      prospectData = prospectDataLocalStorage
        ? JSON.parse(prospectDataLocalStorage)
        : store.getState().prospect.data;

      query = prospectHelper.createQueryObjectFromDataObject({
        data: prospectData,
        skipRebuildToBackendObjectStructure: !!prospectDataLocalStorage, // Local storage data is already in backend query format.
      });
    }

    let baseBody = {
      targetId,
      type,
      columns,
      activeFilters,
      listId: listId ?? null,
      activeSearch,
    };
    let body = useProspectData
      ? { ...baseBody, query }
      : listCriterias
      ? { ...baseBody, query: listCriterias }
      : baseBody;

    const columnOptions = await request({
      data: body,
      url: '/prospect/tableColumnOptions',
      method: 'post',
    });

    return columnOptions ? columnOptions : {};
  } catch (e) {
    console.error('Error in getColumnOptions: ', e);
    return {};
  }
};

export const getFleetDebounced = debounce(getFleet, 1000);

/**
 * Fetch fleet analysis data.
 *
 * @param payload.historic - bool - Get historic fleet data.
 * @param payload.koncern - bool - Get data for whole koncern.
 * @param payload.prospectId - string - TS user id to fetch data for.
 */
export const getFleetAnalysis = async (payload) => {
  const tc = store.getState().user?.info?.lang
    ? store.getState().user.info.lang === 'en'
      ? text.english
      : text.swedish
    : text.swedish;

  try {
    if (!payload || (payload && !payload.prospectId)) {
      return console.error('Missing params in getFleetAnalysis', payload);
    } else {
      payload.prospectId = payload.prospectId.toString();
    }

    const historic = payload.historic ? '/historic/' : '';

    // Get fleet analysis data.
    const data = await request({
      data: {
        koncern: payload.koncern ? 1 : 0,
      },
      method: 'get',
      url: '/fleet/carAnalyse/' + payload.prospectId + historic,
    });

    if (!data || data instanceof Error) {
      if (payload.historic) {
        return store.dispatch({
          type: fleetActionTypes.SET_FLEET_ANALYSIS_HISTORIC,
          payload: {},
        });
      } else {
        return store.dispatch({
          type: fleetActionTypes.SET_FLEET_ANALYSIS,
          payload: {},
        });
      }
    }

    let result: any = {
      target: {},
      total: data.total,
      historic: !!payload.historic,
    };

    result = _generateDataObjectForChartJS1(
      data,
      'boughtPlace',
      result,
      tc.boughtPlace
    );
    result = _generateDataObjectForChartJS1(data, 'brands', result, tc.brands);
    result = _generateDataObjectForChartJS1(
      data,
      'dateAcquired',
      result,
      tc.possessionTime
    );
    result = _generateDataObjectForChartJS1(
      data,
      'date_car_year',
      result,
      tc.carYear
    );
    result = _generateDataObjectForChartJS1(
      data,
      'engineStrength',
      result,
      tc.engineStrength
    );
    // result = _adjustForChartJS1(data, "kaross", result, tc.kaross);
    result = _generateDataObjectForChartJS1(
      data,
      'leasingOwner',
      result,
      tc.leasingOwner
    );
    result = _generateDataObjectForChartJS1(data, 'models', result, tc.models);
    result = _generateDataObjectForChartJS1(
      data,
      'regYear',
      result,
      tc.regYear
    );
    result = _generateDataObjectForChartJS2(
      data,
      'carType',
      result,
      tc.carType
    );
    result = _generateDataObjectForChartJS2(
      data,
      'climate_class',
      result,
      tc.climateClassification
    );
    result = _generateDataObjectForChartJS2(
      data,
      'finance',
      result,
      tc.finance
    );
    result = _generateDataObjectForChartJS2(data, 'fuel', result, tc.fuel);
    result = _generateDataObjectForChartJS2(
      data,
      'inService',
      result,
      tc.inService
    );
    result = _generateDataObjectForChartJS2(data, 'new', result, tc.boughtNew);

    // Get prospect name.
    let target;
    if (companyHelper.isValidOrgNr(payload.prospectId)) {
      const prospectInfo = await request({
        method: 'get',
        url: '/company/companyBasicInfo/' + payload.prospectId,
      });
      target = {
        name: prospectInfo.name,
        prospectId: payload.prospectId,
      };
    } else {
      const prospectInfo = await request({
        method: 'get',
        url: '/privatePerson/' + payload.prospectId,
      });

      if (
        prospectInfo.person &&
        prospectInfo.person[0] &&
        prospectInfo.person[0].person
      ) {
        const person = prospectInfo.person[0].person;
        target = {
          name:
            person.name && person.name.length > 1
              ? person.name
              : personHelper.buildPersonDefaultName(
                  person.gender,
                  person.birthYear,
                  person.zipMuncipality
                ),
          prospectId: payload.prospectId,
        };
      }
    }

    result = {
      ...result,
      target: target,
    };

    if (payload.historic) {
      store.dispatch({
        type: fleetActionTypes.SET_FLEET_ANALYSIS_HISTORIC,
        payload: result,
      });
    } else {
      store.dispatch({
        type: fleetActionTypes.SET_FLEET_ANALYSIS,
        payload: result,
      });
    }
  } catch (err) {
    return console.error('Error in getFleetAnalysis', err);
  }
};

/**
 * Return a fleet summary. Do not save to state.
 *
 * @param payload.prospectId - string
 */
export const getFleetSummary = async (payload) => {
  try {
    if (!payload?.prospectId) {
      return console.error('Missing params in getFleetSummary', payload);
    }

    const data = await request({
      method: 'get',
      data: { getSettings: payload.getSettings ? true : false },
      url: '/fleet/summary/' + payload.prospectId,
    });

    if (data instanceof Error) {
      console.error('Error in getFleetSummary', data);
    }

    return data;
  } catch (err) {
    return console.error('Error in getFleetSummary', err);
  }
};

export const setFleetDisplayHistoric = (val) => {
  store.dispatch({
    type: fleetActionTypes.SET_FLEET_DISPLAY_HISTORIC,
    payload: val,
  });
};

export const getHistoricFleetNew = async (id) => {
  try {
    const data = await request({
      method: 'get',
      url: '/fleet/getHistoricFleet/' + id,
    });

    return data;
  } catch (err) {
    console.error('Error in getHistoricFleetNew', err);
  }
};
